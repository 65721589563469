import React, { useEffect, useMemo, useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import api from '../../api';
import { useNavigator } from '../../compass/navigator';
import { Colors } from '../../constants';
import { FetchError } from '../../helpers/fetcher';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { useNotify } from '../../helpers/use-notify';
import Money from '../../lib/money';
import { Child, DataField, Program } from '../../types';
import Button from '../buttons/button';
import Card from '../card';
import Field from '../custom-fields/field';
import DescriptionItem from '../description-item';
import Divider from '../divider';
import {
  Heading0,
  Heading1,
  Heading2,
  Heading4,
  Heading5,
  Paragraph1,
  Paragraph2
} from '../text-family';
import ProgramImage from './program-image';
import useMeId from '../../hooks/use-me-id';

interface Props {
  program: Program;
  children: Child[];
  fields: DataField[];
}

export default function ProgramRegister({ program, children, fields }: Props) {
  const me = useMeId();
  const navigator = useNavigator();
  const { error } = useNotify();
  const [userFields, childFields] = useMemo<[DataField[], DataField[]]>(
    () => [
      fields.filter((f) => f.target === 'user'),
      fields.filter((f) => f.target === 'child')
    ],
    [fields]
  );

  const isMobile = Dimensions.get('screen').width < 768;
  const [updatedFields, setUpdatedFields] = useState<
    Record<
      number,
      {
        data: object;
        type: 'user' | 'child';
        subjectId: number;
      }[]
    >
  >({});

  const [saving, setSaving] = useState(false);

  function checkValidity(fields: { field: DataField; value: object }[]) {
    return fields.every(({ field, value }) => {
      return fieldIsValid(field, value).valid;
    });
  }

  async function onRegister() {
    if (!fields) {
      return;
    }
    try {
      setSaving(true);
      if (
        !checkValidity(
          fields
            .map((field) =>
              updatedFields[field.id]?.map((f) => ({ field, value: f.data }))
            )
            .filter(Boolean)
            .flat()
            .map((f) => ({
              field: f.field,
              value: f.value
            }))
        )
      ) {
        error('Please check required fields');
        return;
      }
      try {
        const promises: Promise<unknown>[] = [];
        for (const [fieldId, updates] of Object.entries(updatedFields)) {
          for (const update of updates) {
            promises.push(
              api[update.type === 'user' ? 'postUserField' : 'postChildField'](
                update.subjectId,
                +fieldId,
                update.data
              )
            );
          }
        }
        await Promise.all(promises);
      } catch {
        error('Error submitting registration. Please try again later.');
        return;
      }
      const result = await api.preenrol(program.id, {
        preenrolments: children.map((child) => ({
          type: 'child',
          id: child.id
        }))
      });
      if (result instanceof FetchError) {
        throw result;
      }
      navigator.Go('Registration', { program, children, ...result });
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    setUpdatedFields(
      fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.id]: [...(acc[field.id] || [])]
        }),
        {} as Record<
          number,
          {
            data: object;
            type: 'user' | 'child';
            subjectId: number;
          }[]
        >
      )
    );
  }, [fields]);

  if (!me) {
    return null;
  }

  return (
    <ScrollView>
      <View style={{ padding: isMobile ? 8 : 80 }}>
        <Heading0
          style={{
            color: isMobile ? 'black' : 'white',
            marginBottom: isMobile ? 0 : 100,
            paddingHorizontal: 16
          }}
        >
          Registration
        </Heading0>
        <Card style={{ width: '95%', alignSelf: 'center' }}>
          <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <View style={{ width: isMobile ? '100%' : '30%' }}>
              <ProgramImage
                programId={program.id}
                style={{
                  height: 230,
                  width: 'auto',
                  marginVertical: isMobile ? 0 : -100,
                  borderRadius: 20,
                  marginBottom: 16
                }}
              />
            </View>
            <View
              style={{
                padding: isMobile ? 0 : 20,
                marginTop: isMobile ? 0 : -100,
                width: isMobile ? '99%' : '70%'
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Heading1
                  style={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    color: isMobile ? 'black' : 'white'
                  }}
                >
                  {program.pricing.serializedMoney
                    ? Money.deserialize(
                        program.pricing.serializedMoney
                      ).format()
                    : 'Free'}
                </Heading1>
                {program.pricing.serializedMoney && (
                  <Heading5 style={{ color: isMobile ? 'black' : 'white' }}>
                    Registration Fee
                  </Heading5>
                )}
              </View>
              {program.programCheckoutPrompt ? (
                <Card style={{ backgroundColor: Colors.OffWhite }}>
                  <Paragraph1>{program.programCheckoutPrompt}</Paragraph1>
                </Card>
              ) : null}
              {userFields.length ? (
                <DescriptionItem title="Additional Parent Information">
                  <FlatList
                    style={{ width: '100%' }}
                    data={userFields}
                    renderItem={({ item, index }) => (
                      <View>
                        <Field
                          key={index}
                          field={item}
                          displayFormError={false}
                          onChange={(value) => {
                            updatedFields[item.id] = updatedFields[item.id]
                              ? [
                                  ...updatedFields[item.id],
                                  {
                                    data: value,
                                    type: 'user',
                                    subjectId: me
                                  }
                                ]
                              : [
                                  {
                                    data: value,
                                    type: 'user',
                                    subjectId: me
                                  }
                                ];
                            setUpdatedFields({ ...updatedFields });
                          }}
                        />
                        <Divider orientation="horizontal" />
                      </View>
                    )}
                  />
                </DescriptionItem>
              ) : null}

              {childFields.length ? (
                <DescriptionItem title="Additional Child Information">
                  <FlatList
                    style={{ width: '100%' }}
                    data={children}
                    renderItem={({ item: child, index }) => (
                      <View key={index}>
                        <Heading5 style={{ color: 'black' }}>
                          {child.firstName} {child.lastName}
                        </Heading5>
                        <FlatList
                          style={{ width: '100%', paddingHorizontal: '2%' }}
                          data={childFields}
                          renderItem={({ item, index }) => (
                            <View>
                              <Field
                                key={index}
                                field={item}
                                displayFormError={false}
                                onChange={(value) => {
                                  updatedFields[item.id] = updatedFields[
                                    item.id
                                  ]
                                    ? [
                                        ...updatedFields[item.id],
                                        {
                                          data: value,
                                          type: 'child',
                                          subjectId: child.id
                                        }
                                      ]
                                    : [
                                        {
                                          data: value,
                                          type: 'child',
                                          subjectId: child.id
                                        }
                                      ];
                                  setUpdatedFields({ ...updatedFields });
                                }}
                              />
                              <Divider orientation="horizontal" />
                            </View>
                          )}
                        />
                      </View>
                    )}
                  />
                </DescriptionItem>
              ) : null}

              <Divider />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <View style={{ justifyContent: 'center' }}>
                    <Heading5>Total</Heading5>
                  </View>
                  <View
                    style={{
                      justifyContent: 'center',
                      marginLeft: 16,
                      flex: 1
                    }}
                  >
                    <Heading4 style={{ margin: 0 }}>{program.name}</Heading4>
                    <Paragraph2 style={{ margin: 0 }}>
                      {children.length}{' '}
                      {children.length > 1 ? 'children' : 'child'}
                    </Paragraph2>
                  </View>
                </View>
                <View>
                  <Heading2>
                    {program.pricing.serializedMoney
                      ? Money.CAD({
                          cents:
                            Money.deserialize(program.pricing.serializedMoney)
                              .amount * children.length
                        }).format()
                      : 'Free'}
                  </Heading2>
                </View>
              </View>
              <Divider />
              <View style={{ alignItems: 'flex-end' }}>
                <Button
                  style={{ width: '25%' }}
                  onPress={() => onRegister()}
                  loading={saving}
                >
                  Register
                </Button>
              </View>
            </View>
          </View>
        </Card>
      </View>
    </ScrollView>
  );
}
