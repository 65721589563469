import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { NavigatorProps, ScreenProps } from '../types';
import { useCompass } from '../compass/compass.web';

function mustBeOverridden<T>(): T {
  throw new Error('Must be overridden!');
}

const ViewContext = React.createContext({
  Go: (_: string, __: any) => mustBeOverridden<unknown>(),
  Replace: (_: string, __?: any) => mustBeOverridden<unknown>(),
  Back: () => mustBeOverridden<unknown>(),
  State: () => mustBeOverridden<unknown>(),
  Params: () => mustBeOverridden<unknown>()
});

export function useNavigator() {
  return useContext(ViewContext);
}

export default function Navigator(props: NavigatorProps) {
  const compass = useCompass();
  const [screenLookup, setScreenLookup] = useState<
    Record<string, ReactElement<ScreenProps | NavigatorProps>>
  >({});
  const currentScreen = (function (): any {
    const childrenArray = Array.isArray(props.children)
      ? props.children
      : [props.children];
    let child;
    React.Children.forEach(childrenArray, (c) => {
      if (compass.findComponent(c.props.name)) {
        child = c;
      }
    });
    return child ?? <>No screen found</>;
  })();

  useEffect(() => {
    function checkChildren() {
      const lookup: Record<
        string,
        ReactElement<ScreenProps | NavigatorProps>
      > = {};
      const childrenArray = Array.isArray(props.children)
        ? props.children
        : [props.children];
      React.Children.forEach(childrenArray, (child) => {
        if (!React.isValidElement(child)) {
          throw Error('Found invalid react element as passed as child');
          // @ts-ignore
        }
        lookup[child.props.name] = child;
        if (!lookup[child.props.name]) {
          throw Error('Unknown or invalid screen');
        }
      });
      setScreenLookup(lookup);
    }

    checkChildren();
  }, [props.children]);

  function paramsToQueryString(params: Record<string, any> = {}): string {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      if (
        Array.isArray(value) &&
        value.every(
          (item) => typeof item === 'string' || typeof item === 'number'
        )
      ) {
        for (const item of value) {
          searchParams.append(key, item.toString());
        }
      } else if (typeof value === 'string' || typeof value === 'number') {
        searchParams.set(key, value.toString());
      } else {
        const objectId = crypto.randomUUID();
        window.localStorage.setItem(objectId, JSON.stringify(value));
        searchParams.set('__' + key, objectId);
      }
    }
    const qs = searchParams.toString();
    return qs ? `?${qs}` : '';
  }

  function groupParamsByKey(params: URLSearchParams) {
    const groupedParams = [...params.entries()].reduce((cur: any, tuple) => {
      const [key, val] = tuple;
      if (cur.hasOwnProperty(key)) {
        if (Array.isArray(cur[key])) {
          cur[key] = [...cur[key], val];
        } else {
          cur[key] = [cur[key], val];
        }
      } else {
        cur[key] = val;
      }

      return cur;
    }, {});
    for (const key in groupedParams) {
      if (key.startsWith('__')) {
        const objectId = groupedParams[key];
        groupedParams[key.slice(2)] = JSON.parse(
          window.localStorage.getItem(objectId) ?? 'null'
        );
      }
    }
    return groupedParams;
  }

  function Go(screen: string, params?: object) {
    // console.log('go', screen, params);
    history.pushState(
      null,
      '',
      screenLookup[screen].props.path + paramsToQueryString(params)
    );
    compass.updateLocation();
  }

  function Replace(screen: string, params?: any) {
    history.replaceState(
      null,
      '',
      screenLookup[screen].props.path + paramsToQueryString(params)
    );
    compass.updateLocation();
  }

  function State(): string {
    // @ts-ignore
    return currentScreen?.props.path;
  }

  function Back() {
    history.back();
    compass.updateLocation();
  }

  function Params(): any {
    return groupParamsByKey(new URLSearchParams(compass.query));
  }

  const Component = currentScreen;
  const PageContainer = props.navigation;

  return (
    <ViewContext.Provider value={{ Go, Back, Replace, State, Params }}>
      {PageContainer ? (
        <PageContainer>{Component}</PageContainer>
      ) : (
        <View style={{ flex: 1 }}>{Component}</View>
      )}
    </ViewContext.Provider>
  );
}
