import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import { Colors } from '../../constants';
import {
  Home,
  User as UserIcon,
  Calendar,
  DollarSign,
  Smile,
  LogOut
} from 'react-native-feather';
import { useNavigator } from '../../compass/navigator';
import HoverableOpacity from '../buttons/hoverable-opacity';
import { User } from '../../types';
import NavigationButton from './navigation-button';
import useTenantId from '../../hooks/use-tenant-id';
import { Heading2, Paragraph2 } from '../text-family';

interface Props {
  user?: User;
}

export default function SideNavigationBar({ user }: Props) {
  const navigation = useNavigator();
  const [hover, setHover] = useState<string>();
  const { width: screenWidth } = Dimensions.get('window');
  const iconSize = screenWidth / 90;
  const fontSize = screenWidth / 110;
  const tenantId = useTenantId();

  return (
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '14%',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: '#003A50',
        shadowColor: Colors.Gray,
        shadowOffset: {
          width: 1,
          height: 0
        },
        shadowOpacity: 0.05,
        shadowRadius: 3.84
      }}
    >
      <HoverableOpacity
        onMouseEnter={() => setHover('Menu')}
        onMouseLeave={() => setHover('')}
        style={styles.menuButton}
      >
        <Image
          resizeMode="contain"
          style={{
            width: '70%',
            height: 100,
            alignItems: 'center'
          }}
          source={{
            uri: `https://assets.rivercitytech.cloud/nodes/1/enrolr/tenants/${
              tenantId ?? 1
            }/branding/main.img`
          }}
        />
      </HoverableOpacity>
      <View style={{ justifyContent: 'center' }}>
        <NavigationButton
          text="Home"
          currentTab={navigation.State() === '/home'}
          icon={Home}
          onClick={() => navigation.Replace('HomeTab')}
        />

        <NavigationButton
          text="Children"
          currentTab={navigation.State() === '/children'}
          icon={Smile}
          onClick={() => navigation.Replace('ChildrenTab')}
        />

        <NavigationButton
          text="Programs"
          currentTab={navigation.State() === '/programs'}
          icon={Calendar}
          onClick={() => navigation.Replace('ProgramsTab')}
        />

        <NavigationButton
          text="Billing"
          currentTab={navigation.State() === '/billing'}
          icon={DollarSign}
          onClick={() => navigation.Replace('BillingTab')}
        />

        <NavigationButton
          text="Profile"
          currentTab={navigation.State() === '/profile'}
          icon={UserIcon}
          onClick={() => navigation.Replace('ProfileTab')}
        />

        {/* <NavigationButton
          text="Notifications"
          currentTab={navigation.State() === '/notifications'}
          onClick={() => navigation.Go('NotificationsTab')}
          icon={MessageSquare}
        /> */}
      </View>
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          paddingHorizontal: 16,
          paddingVertical: 20,
          borderTopColor: Colors.GrayBorder,
          borderTopWidth: 1
        }}
      >
        <View style={{}}>
          <Heading2
            style={{
              color: Colors.Secondary,
              // textAlign: 'center',
              fontSize: fontSize,
              paddingVertical: 0,
              marginVertical: 0
            }}
          >
            {user?.firstName} {user?.lastName}
          </Heading2>
          <Paragraph2 style={{ fontSize: 10 }}>{user?.username}</Paragraph2>
        </View>

        <HoverableOpacity
          onMouseEnter={() => setHover('Logout')}
          onMouseLeave={() => setHover('')}
          onPress={() => navigation.Replace('Logout')}
        >
          <LogOut
            width={iconSize}
            height={iconSize}
            style={{ margin: 16 }}
            color={hover === 'Logout' ? Colors.ButtonPrimary : Colors.Secondary}
          />
        </HoverableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  menuButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    paddingVertical: 8
  }
});
