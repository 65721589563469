import React from 'react';
import { View, Text } from 'react-native';
import { FieldProps } from '../../types';
import { Heading6 } from '../text-family';
import DateField from './date-field';
import EmailField from './email-field';
import NumberField from './number-field';
import PhoneNumberField from './phone-number-field';
import PostalCodeField from './postal-code-field';
import SelectField from './select-field';
import TextAreaField from './text-area-field';
import TextField from './text-field';
import WaiverField from './waiver-field';
import { Screen } from '../../constants';
import { breakpoint } from '../../helpers/breakpoint';

export default function Field(props: FieldProps) {
  const screen = breakpoint();
  function renderField() {
    switch (props?.field?.type) {
      case 'text':
        return <TextField {...props} />;
      case 'textArea':
        return <TextAreaField {...props} />;
      case 'select':
        return <SelectField {...props} />;
      case 'date':
        return <DateField {...props} />;
      case 'number':
        return <NumberField {...props} />;
      case 'phoneNumber':
        return <PhoneNumberField {...props} />;
      case 'waiver':
        return <WaiverField {...props} />;
      case 'email':
        return <EmailField {...props} />;
      case 'postalCode':
        return <PostalCodeField {...props} />;
      default:
        return (
          <View>
            <Text style={{ color: 'red' }}>*Error Loading field*</Text>
          </View>
        );
    }
  }
  return (
    <View
      style={{
        flexDirection: screen !== Screen.sm ? 'row' : 'column',
        justifyContent: 'space-between',
        alignContent: 'flex-start',
        width: '100%'
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          width: screen !== Screen.sm ? '40%' : 'none'
        }}
      >
        <Heading6>
          {props?.field?.name}
          {props?.field?.mandatory && (
            <Heading6 style={{ color: 'red', marginLeft: 2 }}>*</Heading6>
          )}
        </Heading6>
      </View>
      <View style={{ width: screen !== Screen.sm ? '50%' : 'none' }}>
        {renderField()}
      </View>
    </View>
  );
}
