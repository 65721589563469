import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView, TextInput, View } from 'react-native';
import api from '../../../api';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import ContentContainer from '../../../components/content-container';
import Field from '../../../components/custom-fields/field';
import Divider from '../../../components/divider';
import Loading from '../../../components/loading';
import PageTitle from '../../../components/page-title';
import { Heading6 } from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { breakpoint } from '../../../helpers/breakpoint';
import { fieldIsValid } from '../../../helpers/field-is-valid';
import { fieldTrimmer } from '../../../helpers/field-trimmer';
import { useNotify } from '../../../helpers/use-notify';
import { useUserTenantEntries } from '../../../hooks';
import useMe from '../../../hooks/use-me';
import useTenantId from '../../../hooks/use-tenant-id';
import { DataEntry, DataField } from '../../../types';

export default function ProfileDetails() {
  const screenSize = breakpoint();
  const navigator = useNavigator();
  const tenantId = useTenantId();
  const me = useMe();
  const { data: fields } = useUserTenantEntries(me?.id, tenantId);
  const [updatedFields, setUpdatedFields] = useState<Record<number, object>>(
    {}
  );
  const [userData, setUserData] = useState({
    firstName: me?.firstName,
    lastName: me?.lastName,
    pickupPasscode: me?.pickupPasscode
  });
  const { success, error } = useNotify();
  // const emailRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

  function checkValidity(
    fields: { field: DataField; value: object }[]
  ): boolean {
    return (
      userData.firstName !== undefined &&
      userData.firstName.length >= 1 &&
      userData.lastName !== undefined &&
      userData.lastName.length >= 1 &&
      fields.every(({ field, value }) => fieldIsValid(field, value).valid)
    );
  }

  function checkChanges(): boolean {
    return (
      userData.firstName !== me?.firstName ||
      userData.lastName !== me?.lastName ||
      userData.pickupPasscode !== me?.pickupPasscode ||
      Object.entries(updatedFields).length > 0
    );
  }

  async function onSave() {
    if (!checkChanges()) {
      navigator.Back();
      return;
    }
    if (
      !checkValidity(
        fields?.map(({ field, entry }) => ({
          field,
          value: updatedFields[field.id] ?? entry?.data ?? {}
        })) ?? []
      )
    ) {
      error('Please fill in all required fields');
      return;
    }
    try {
      await api.updateUser(me!.id, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        pickupPasscode: userData.pickupPasscode,
        details: Object.entries(updatedFields).map(([id, value]) => ({
          fieldId: +id,
          value: fieldTrimmer(value)
        }))
      });
      navigator.Back();
      success('Successfully updated profile details');
    } catch (e) {
      error((e as Error).message);
    }
  }

  useEffect(() => {
    setUserData({
      firstName: me?.firstName,
      lastName: me?.lastName,
      pickupPasscode: me?.pickupPasscode
    });
  }, [me]);

  return (
    <ScrollView style={{ flex: 1 }}>
      <PageTitle title="Profile" />
      <ContentContainer>
        {fields && me ? (
          <>
            <Card>
              <View
                style={{
                  flexDirection: screenSize == Screen.lg ? 'row' : 'column',
                  justifyContent:
                    screenSize == Screen.lg ? 'space-between' : `flex-start`
                }}
              >
                <View
                  style={{ width: screenSize == Screen.lg ? '49%' : '100%' }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Heading6
                      style={{ marginLeft: 25, margin: 0, marginBottom: 4 }}
                    >
                      First Name
                    </Heading6>
                    <Heading6
                      style={{ color: 'red', margin: 0, marginBottom: 4 }}
                    >
                      *
                    </Heading6>
                  </View>
                  <TextInput
                    style={{
                      borderColor:
                        !userData.firstName || userData.firstName?.length < 2
                          ? 'red'
                          : 'rgba(112, 112, 112, 0.5)',
                      borderWidth: 1,
                      borderRadius: 15,
                      backgroundColor: Colors.White,
                      margin: 4,
                      marginVertical: 6,
                      paddingHorizontal: 6,
                      paddingVertical: 6
                    }}
                    value={userData.firstName}
                    onChangeText={(text) => {
                      setUserData({ ...userData, firstName: text.trim() });
                    }}
                  />
                </View>

                <View
                  style={{
                    width: screenSize == Screen.lg ? '49%' : '100%',
                    marginTop: screenSize == Screen.lg ? 0 : 10
                  }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Heading6
                      style={{ marginLeft: 25, margin: 0, marginBottom: 4 }}
                    >
                      Last Name
                    </Heading6>
                    <Heading6
                      style={{ color: 'red', margin: 0, marginBottom: 4 }}
                    >
                      *
                    </Heading6>
                  </View>
                  <TextInput
                    style={{
                      borderColor:
                        !userData.lastName || userData.lastName?.length < 2
                          ? 'red'
                          : 'rgba(112, 112, 112, 0.5)',
                      borderWidth: 1,
                      borderRadius: 15,
                      backgroundColor: Colors.White,
                      margin: 4,
                      marginVertical: 6,
                      paddingHorizontal: 6,
                      paddingVertical: 6
                    }}
                    value={userData.lastName}
                    onChangeText={(text) => {
                      setUserData({ ...userData, lastName: text.trim() });
                    }}
                  />
                </View>
              </View>
              <View style={{ marginTop: 10 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Heading6
                    style={{ marginLeft: 25, margin: 0, marginBottom: 4 }}
                  >
                    Pickup Passcode
                  </Heading6>
                </View>
                <TextInput
                  style={{
                    borderColor: 'rgba(112, 112, 112, 0.5)',
                    borderWidth: 1,
                    borderRadius: 15,
                    backgroundColor: Colors.White,
                    margin: 4,
                    marginVertical: 6,
                    paddingHorizontal: 6,
                    paddingVertical: 6
                  }}
                  value={userData.pickupPasscode ?? ''}
                  onChangeText={(text) => {
                    setUserData({ ...userData, pickupPasscode: text.trim() });
                  }}
                />
              </View>
            </Card>

            <Divider orientation="horizontal" />

            <FlatList
              data={fields}
              renderItem={({ item, index }) => (
                <View key={index} style={{ marginHorizontal: '2%' }}>
                  <Field
                    key={index}
                    field={item.field as unknown as DataField}
                    entry={item.entry as unknown as DataEntry}
                    displayFormError={false}
                    onChange={(value) => {
                      updatedFields[item.field.id] = value;
                      setUpdatedFields({ ...updatedFields });
                    }}
                  />
                  <Divider orientation="horizontal" />
                </View>
              )}
            />
            <View style={{ marginVertical: 20 }}>
              <Button onPress={onSave}>Save</Button>
            </View>
          </>
        ) : (
          <Loading />
        )}
      </ContentContainer>
    </ScrollView>
  );
}
