import React from 'react';
import { TouchableOpacity, TouchableWithoutFeedbackProps } from 'react-native';

export interface HoverableWithoutFeedbackProps
  extends TouchableWithoutFeedbackProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export default function HoverableOpacity(props: HoverableWithoutFeedbackProps) {
  return (
    <TouchableOpacity
      {...props}
      style={props.style}
      // @ts-ignore
      onMouseEnter={props.onMouseEnter}
      // @ts-ignore
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </TouchableOpacity>
  );
}
