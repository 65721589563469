import { View, ViewProps } from 'react-native';
import { breakpoint } from '../helpers/breakpoint';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';
import { Screen } from '../constants';

interface Props extends ViewProps {}

export default function ContentContainer(props: Props) {
  const screenSize = breakpoint();
  return (
    <View
      {...composePropsWithDefaultStyles(
        {
          flex: 1,
          width: '100%',
          paddingTop: screenSize !== Screen.sm ? 16 : 8,
          paddingHorizontal: screenSize !== Screen.sm ? '10%' : 16
        },
        props as any
      )}
    />
  );
}
