import React, { useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import api, { apiResult } from '../../../api';
import { useNavigator } from '../../../compass/navigator';
import Loading from '../../../components/loading';
import Pr from '../../../components/program/program-register';
import { Colors } from '../../../constants';
import { FetchError } from '../../../helpers/fetcher';
import { useProgram, useProgramFields } from '../../../hooks';
import { Child } from '../../../types';

export default function ProgramDetails() {
  const navigator = useNavigator();
  const params = navigator.Params();
  const { data: program } = useProgram(params.programId);
  const [children, setChildren] = useState<Child[]>();
  const { data: fields } = useProgramFields(params.programId);
  const isMobile = Dimensions.get('window').width < 768;

  useEffect(() => {
    async function getChildren() {
      let childArray: Child[] = [];
      if (!Array.isArray(params.childId)) {
        const child = apiResult(await api.getChild(params.childId));
        if (child instanceof FetchError) {
          throw child;
        }
        childArray.push(child.child);
      } else {
        for (let i = 0; i < params.childId.length; i++) {
          const child = apiResult(await api.getChild(+params.childId[i]));
          if (child instanceof FetchError) {
            throw child;
          }
          childArray.push(child.child);
        }
      }
      setChildren(childArray);
    }
    getChildren();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      {!isMobile && (
        <View
          style={{
            backgroundColor: Colors.Primary,
            position: 'absolute',
            width: '90%',
            height: '33%',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            alignSelf: 'center'
          }}
        />
      )}
      {program && children && fields ? (
        <Pr program={program} children={children} fields={fields} />
      ) : (
        <Loading />
      )}
    </View>
  );
}
