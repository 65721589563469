import { View, ViewProps } from 'react-native';
import { Inbox } from 'react-native-feather';
import { Colors } from '../constants';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';
import { Heading5, Paragraph2 } from './text-family';

interface EmptyProps extends ViewProps {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
}

export default function Empty(props: EmptyProps) {
  return (
    <View
      {...composePropsWithDefaultStyles(
        {
          flex: 1,
          justifyContent: 'center' as const,
          marginVertical: '20%',
          alignItems: 'center' as const
        },
        props as any
      )}
    >
      {props.icon ? (
        props.icon
      ) : (
        <Inbox color={Colors.Primary} width={60} height={60} />
      )}
      <Heading5>{props.title || 'Empty'}</Heading5>
      <Paragraph2 style={{ textAlign: 'center' }}>
        {props.description ?? null}
      </Paragraph2>
    </View>
  );
}
