import { NavigatorProps, Navigator as NavigatorType } from '../types';

const {
  default: navigatorInferred,
  useNavigator: useNavigatorInferred
} = require('./navigator');

export const Navigator: (props: NavigatorProps) => JSX.Element =
  navigatorInferred;

export const useNavigator: () => NavigatorType = useNavigatorInferred;
