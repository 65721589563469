import { useState } from 'react';
import { View, Platform, ActivityIndicator, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import api from '../../../api';
import { useNavigator } from '../../../compass/navigator';
import BackButton from '../../../components/buttons/back-button';
import Button from '../../../components/buttons/button';
import Field from '../../../components/custom-fields/field';
import {
  Heading1,
  Heading3,
  Paragraph1
} from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { useAuth } from '../../../context/auth-context';
import { breakpoint, buttonHorizontalWidth } from '../../../helpers/breakpoint';
import { FetchError } from '../../../helpers/fetcher';
import { fieldIsValid } from '../../../helpers/field-is-valid';
import { fieldTrimmer } from '../../../helpers/field-trimmer';
import { useNotify } from '../../../helpers/use-notify';
import { useUserTenantFields } from '../../../hooks';
import useMe from '../../../hooks/use-me';
import useMeId from '../../../hooks/use-me-id';
import storage from '../../../storage/storage.isomorphic';
import { DataField } from '../../../types';

export default function TenantRequirements() {
  const navigator = useNavigator();
  const meId = useMeId();
  const user = useMe();
  const params = navigator.Params();
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
  const [_, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const width = buttonHorizontalWidth();
  const screen = breakpoint();
  const tenantId = params.tenantId;
  const auth = useAuth();
  const { success, error } = useNotify();

  const { data: userFields, loading: loadingUserFields } =
    useUserTenantFields(tenantId);
  const [updatedFields, setUpdatedFields] = useState<Record<number, object>>(
    {}
  );

  function checkValidity(
    fields: { field: DataField; value: object }[]
  ): boolean {
    return fields.every(({ field, value }) => {
      return fieldIsValid(field, value).valid;
    });
  }

  async function onSave() {
    setLoading(true);
    if (!tenantId || !meId) {
      setLoading(false);
      return;
    }

    if (
      !checkValidity(
        userFields?.map((field) => ({
          field,
          value: updatedFields[field.id] ?? {}
        })) ?? []
      )
    ) {
      error('Please check required fields');
      setFormError(true);
      setLoading(false);
      return;
    }

    const response = await api.postAttachUserToTenant(tenantId, meId!);
    if (response instanceof FetchError) {
      setLoading(false);
      return;
    }

    try {
      await api.updateUser(meId!, {
        details: Object.entries(updatedFields).map(([id, value]) => ({
          fieldId: +id,
          value: fieldTrimmer(value)
        }))
      });
      success('Successfully Added to Tenant');
      await storage.setItem('tenantId', tenantId.toString());
    } catch (e) {
      error('Failed to add you to tenant');
    }

    setLoading(false);
    auth.refresh();
  }

  return (
    <SafeAreaView
      edges={['top', 'bottom']}
      style={{
        flex: 1
      }}
    >
      {isMobile ? <BackButton>Back</BackButton> : null}
      {!tenantId || !userFields || loadingUserFields || !user ? (
        <ActivityIndicator />
      ) : (
        <View style={{ padding: isMobile ? 5 : '4%', flex: 1 }}>
          <View style={{ paddingBottom: 30 }}>
            <Heading1>Hey {user.firstName.toUpperCase()}, </Heading1>

            {!userFields.length ? (
              <View>
                <Heading3>You are good to go!</Heading3>
                <Paragraph1>Press submit to continue.</Paragraph1>
              </View>
            ) : (
              <View>
                <Heading3>Let's get you set up and running!</Heading3>
                <Paragraph1>
                  This service provider requires more information from you.
                  Please fill the form below to continue.
                </Paragraph1>
              </View>
            )}
          </View>
          <View
            style={{
              paddingHorizontal: screen == Screen.lg ? 40 : 4,
              paddingBottom: 40,
              flex: 1
            }}
          >
            <FlatList
              data={userFields.map((field) => ({
                field: field,
                entry: undefined
              }))}
              renderItem={({ item, index }) => (
                <View
                  key={index}
                  style={{
                    paddingVertical: 12,
                    borderColor: Colors.GrayBorder,
                    borderBottomWidth: 1,
                    borderTopWidth: index == 0 ? 1 : 0
                  }}
                >
                  <Field
                    field={item.field}
                    entry={item.entry}
                    // displayFormError={displayFormErrors}
                    onChange={(value) => {
                      updatedFields[item.field.id] = value;
                      setUpdatedFields({ ...updatedFields });
                    }}
                  />
                </View>
              )}
            />
          </View>

          <View style={{ width: '100%', alignItems: 'center' }}>
            <Button
              type={'primary'}
              loading={loading}
              style={{ width: width }}
              onPress={() => onSave()}
            >
              Submit
            </Button>
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}
