export enum Currency {
  CAD = 'CAD',
  USD = 'USD'
}

export default class Money {
  public currency: Currency;
  public amount: number;

  public constructor({
    currency,
    amount
  }: {
    currency: Currency;
    amount: number;
  }) {
    this.currency = currency;
    this.amount = amount;
  }

  public multiplyBy(n: number): this {
    this.amount *= n;
    return this;
  }

  public add(amount: number): this;
  public add(money: Money): this;
  public add(amount: number | Money): this {
    if (typeof amount === 'number') {
      this.amount += amount;
    } else {
      if (amount.currency !== this.currency) {
        throw new Error(
          `cannot add different currencies. expected ${this.currency} but got ${amount.currency}`
        );
      }
      this.amount += amount.amount;
    }
    return this;
  }

  public format(): string {
    switch (this.currency) {
      case Currency.CAD:
      case Currency.USD:
        return `$${(this.amount / 100).toFixed(2)}`;
      default:
        throw new Error(`Unexpected currency: ${this.currency}`);
    }
  }
  public get stripeCurrency(): string {
    switch (this.currency) {
      case Currency.CAD:
        return 'cad';
      case Currency.USD:
        return 'usd';
      default:
        return 'cad';
    }
  }

  public serialize(): string {
    return `${this.currency};${this.amount}`;
  }

  public static deserialize(serialized: string): Money {
    const [currency, amount] = serialized.split(';');
    return new Money({ currency: currency as Currency, amount: +amount });
  }

  public static CAD({ cents }: { cents: number }): Money {
    return new Money({ currency: Currency.CAD, amount: cents });
  }

  public static USD({ cents }: { cents: number }): Money {
    return new Money({ currency: Currency.USD, amount: cents });
  }
}
