import React from 'react';
import { View, ViewProps } from 'react-native';
import { Colors, Screen } from '../constants';
import { breakpoint } from '../helpers/breakpoint';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends ViewProps {}

export default function Card(props: Props) {
  const screenSize = breakpoint();
  return (
    <View
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: Colors.White,
          borderRadius: 20,
          padding: screenSize == Screen.lg ? 20 : 12,
          margin: screenSize == Screen.lg ? 12 : 4,
          shadowColor: Colors.Gray,
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.05,
          shadowRadius: 3.84
        },
        props as any
      )}
    />
  );
}
