import { breakpoint } from '../helpers/breakpoint';
import { Colors, Screen } from '../constants';
import { Heading0 } from './text-family';
import { Dimensions, View } from 'react-native';
import { ReactNode } from 'react';

interface Props {
  title: string;
  button?: ReactNode;
}

export default function PageTitle({ title, button }: Props) {
  const screenSize = breakpoint();
  const height = Dimensions.get('window').height;
  return (
    <View
      style={{
        backgroundColor: Colors.Primary,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 20,
        width: '92%',
        height: height * 0.08,
        // alignContent: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Heading0
        style={{
          color: 'white',
          fontSize: screenSize == Screen.sm ? 30 : 45,
          padding: 4,
          paddingHorizontal: screenSize == Screen.sm ? 8 : 16
        }}
      >
        {title}
      </Heading0>
      <View style={{ paddingRight: 12 }}>{button}</View>
    </View>
  );
}
