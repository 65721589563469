import { DataFieldType } from '../constants';
import { DataField, Validity } from '../types';

const emailRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export function fieldIsValid(
  field: DataField | Omit<DataField, 'id'>,
  value: object
): Validity {
  if (!field.mandatory) {
    return { valid: true, message: '' };
  }
  switch (field.type) {
    case DataFieldType.Select:
      return {
        valid:
          value.hasOwnProperty('selection') &&
          (field.data as { selectOptions: any[] }).selectOptions.includes(
            (value as { selection: any }).selection
          ),
        message: (
          field.data as { selectOptions: any[] }
        ).selectOptions.includes((value as { selection: any }).selection)
          ? ''
          : 'Please select a valid option'
      };
    case DataFieldType.Number:
      return {
        valid:
          value.hasOwnProperty('number') &&
          (value as { number: number }).number !== undefined,
        message:
          (value as { number: number }).number !== undefined
            ? ''
            : 'Please enter a valid number'
      };
    case DataFieldType.Date:
      return {
        valid:
          value.hasOwnProperty('date') &&
          (value as { date: number }).date != undefined,
        message:
          (value as { date: number }).date != undefined
            ? ''
            : 'Please enter a valid date'
      };
    case DataFieldType.Waiver:
      return {
        valid:
          value.hasOwnProperty('agreed') &&
          (value as { agreed: boolean }).agreed,
        message: (value as { agreed: boolean }).agreed
          ? ''
          : 'Please read and agree to the waiver'
      };
    case DataFieldType.Email:
      return {
        valid:
          value.hasOwnProperty('text') &&
          (value as { text: string }).text.length > 2 &&
          emailRegex.test((value as { text: string }).text),
        message: emailRegex.test((value as { text: string }).text)
          ? ''
          : 'Email is not valid. Please enter a valid email address'
      };
    case DataFieldType.PostalCode:
      return {
        valid:
          value.hasOwnProperty('text') &&
          (value as { text: string }).text.length > 0 &&
          postalCodeRegex.test((value as { text: string }).text),
        message: postalCodeRegex.test((value as { text: string }).text)
          ? ''
          : 'Postal Code is not valid'
      };
    case DataFieldType.Text:
    case DataFieldType.TextArea:
    case DataFieldType.PhoneNumber:
      return {
        valid:
          value.hasOwnProperty('text') &&
          (value as { text: string }).text?.length >= 1,
        message:
          (value as { text: string }).text?.length >= 1
            ? ''
            : 'This field is required'
      };
  }
}
