import React from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import { Colors } from '../../constants';
import {
  Home,
  User,
  Calendar,
  DollarSign,
  // MessageSquare,
  Smile
} from 'react-native-feather';
import { useNavigator } from '../../compass/navigator';
import HoverableOpacity from '../buttons/hoverable-opacity';

export default function BottomNavigationBar() {
  const navigation = useNavigator();
  const width = Dimensions.get('window').width;
  const iconWidth = width / 16;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: '#003A50',
        shadowColor: Colors.Gray,
        shadowOffset: {
          width: 0,
          height: -2
        },
        shadowOpacity: 0.05,
        shadowRadius: 3.84,
        paddingVertical: 5,
        borderTopColor: 'rgba(0,0,0,0.1)',
        borderTopWidth: 1
      }}
    >
      <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('HomeTab')}
      >
        <Home
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/home' ? Colors.Primary : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/home' ? styles.highlightText : styles.text
          }
        >
          Home
        </Text>
      </HoverableOpacity>
      <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('ChildrenTab')}
      >
        <Smile
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/children'
              ? Colors.Primary
              : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/children'
              ? styles.highlightText
              : styles.text
          }
        >
          Children
        </Text>
      </HoverableOpacity>
      <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('ProgramsTab')}
      >
        <Calendar
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/programs'
              ? Colors.Primary
              : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/programs'
              ? styles.highlightText
              : styles.text
          }
        >
          Programs
        </Text>
      </HoverableOpacity>
      <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('BillingTab')}
      >
        <DollarSign
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/billing'
              ? Colors.Primary
              : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/billing'
              ? styles.highlightText
              : styles.text
          }
        >
          Billing
        </Text>
      </HoverableOpacity>

      <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('ProfileTab')}
      >
        <User
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/profile'
              ? Colors.Primary
              : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/profile'
              ? styles.highlightText
              : styles.text
          }
        >
          Profile
        </Text>
      </HoverableOpacity>
      {/* <HoverableOpacity
        style={styles.navButton}
        onPress={() => navigation.Replace('NotificationsTab')}
      >
        <MessageSquare
          style={styles.icon}
          width={iconWidth}
          height={iconWidth}
          color={
            navigation.State() === '/notifications'
              ? Colors.Primary
              : Colors.Secondary
          }
        />
        <Text
          style={
            navigation.State() === '/notifications'
              ? styles.highlightText
              : styles.text
          }
        >
          Messages
        </Text>
      </HoverableOpacity> */}
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    alignSelf: 'center',
    paddingBottom: 5,
    flex: 1
  },
  navButton: { flex: 1, paddingVertical: 5 },
  text: {
    display: 'flex',
    alignSelf: 'center',
    color: Colors.Secondary,
    fontSize: 10
  },
  highlightText: {
    display: 'flex',
    alignSelf: 'center',
    color: Colors.Primary,
    fontSize: 10
  }
});
