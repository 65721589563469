import React, { useState } from 'react';
import { TextInput, View } from 'react-native';
import { Colors } from '../../constants';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import { Paragraph2 } from '../text-family';

export default function PostalCodeField({
  field,
  entry,
  displayFormError,
  onChange
}: FieldProps) {
  const [value, setValue] = useState(
    entry ? (entry?.data as { text: string }).text : ''
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!value?.length : true,
    message: 'This field is required'
  });
  return (
    <View>
      <TextInput
        style={{
          borderColor:
            displayFormError || !error.valid
              ? 'red'
              : 'rgba(112, 112, 112, 0.5)',
          borderWidth: 1,
          borderRadius: 15,
          backgroundColor: Colors.White,
          margin: 4,
          marginVertical: 6,
          paddingHorizontal: 12,
          paddingVertical: 6
        }}
        value={value}
        onChangeText={(text) => {
          if (onChange) {
            onChange({ text }, fieldIsValid(field, { text }).valid);
          }
          setError(fieldIsValid(field, { text }));
          setValue(text);
        }}
      />
      {field.mandatory ? (
        !error.valid ? (
          <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
            {error?.message}
          </Paragraph2>
        ) : null
      ) : null}
    </View>
  );
}
