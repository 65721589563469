import React from 'react';
import { Dimensions, View, ViewProps } from 'react-native';
import composePropsWithDefaultStyles from '../../../helpers/compose-props-with-default-styles';
import BottomNavigationBar from '../../navigation-bar/bottom-navigation-bar';
import { Colors } from '../../../constants';

interface Props extends ViewProps {}

export default function PageContainerMobile(props: Props) {
  return (
    <View style={{ height: Dimensions.get('window').height, display: 'flex' }}>
      <View style={{ backgroundColor: Colors.Background, flex: 1 }}>
        <View {...composePropsWithDefaultStyles({ flex: 1 }, props as any)} />
      </View>
      <View style={{ height: '8%' }}>
        <BottomNavigationBar />
      </View>
    </View>
  );
}
