import React from 'react';
import {
  Dimensions,
  FlexAlignType,
  Image,
  ImageBackground,
  StatusBar,
  View,
  StyleSheet,
  Pressable
} from 'react-native';
import { useNavigator } from '../../compass/navigator';
import Button from '../../components/buttons/button';
import PageContainerAuth from '../../components/page-container/page-container-auth';
import { Heading2, Heading4, Heading5 } from '../../components/text-family';
import { Colors } from '../../constants';
import { buttonHorizontalWidth } from '../../helpers/breakpoint';

export default function Welcome() {
  const navigator = useNavigator();
  return (
    <ImageBackground
      source={require('../../assets/welcome.jpg')}
      style={{
        width: '100%',
        height: Dimensions.get('window').height,
        overflow: 'hidden'
      }}
    >
      <PageContainerAuth style={styles.column}>
        <StatusBar barStyle="dark-content" />
        <View style={styles.column}>
          <Image
            style={{ width: 300, height: 300 }}
            source={require('../../assets/pic1.svg')}
          />
        </View>
        <View style={{ ...styles.column, width: '80%' }}>
          <View style={{ padding: 5 }}>
            <View style={{ marginVertical: 20 }}>
              <Heading2 style={{}}>Hey! Welcome to Enrolr.</Heading2>
            </View>
          </View>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <Button
              style={{ width: buttonHorizontalWidth() }}
              onPress={() => navigator.Go('Login')}
            >
              Login
            </Button>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 12
              }}
            >
              <Heading5 style={{ textAlign: 'center', alignContent: 'center' }}>
                New to Enrolr?
              </Heading5>
              <Pressable onPress={() => navigator.Go('SignUp')}>
                <Heading4 style={{ color: Colors.Primary }}>Sign Up</Heading4>
              </Pressable>
            </View>
          </View>
        </View>
      </PageContainerAuth>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center' as FlexAlignType,
    justifyContent: 'center',
    padding: 5
  }
});
