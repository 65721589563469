import { View, ActivityIndicator } from 'react-native';
import { Colors } from '../constants';

export default function PageLoading() {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <ActivityIndicator size="large" color={Colors.Primary} />
    </View>
  );
}
