import { useState, useEffect } from 'react';
import storage from '../storage/storage.isomorphic';

export default function useTenantId() {
  const [tenantId, setTenantId] = useState<number>();

  async function getTenantId() {
    const result = await storage.getItem('tenantId');
    if (!result) {
      return;
    } else {
      setTenantId(+result);
    }
  }

  useEffect(() => {
    getTenantId();
  }, []);

  return tenantId;
}
