import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import WebView, { WebViewNavigation } from 'react-native-webview';
import config from '../../configs/config';
import PageContainerAuth from '../../components/page-container/page-container-auth';
import { URLSearchParams } from 'react-native-url-polyfill';
import initializeApplication from '../../helpers/initialize-application';
import { useAuth } from '../../context/auth-context';
import BackButton from '../../components/buttons/back-button';
import { useNavigator } from '../../compass/navigator';
import PageLoading from '../../components/full-page-loading';

export default function LoginWebview() {
  const navigator = useNavigator();
  const params = navigator.Params();

  const [, setLoadingWebview] = useState(true);
  const isNative = Platform.OS === 'ios' || Platform.OS === 'android';
  const auth = useAuth();

  function redirect() {
    window.location.href = params.first_time
      ? `${config.authUrlWeb}&first_time=${params.first_time}`
      : config.authUrlWeb;
  }

  async function onNavigationChange({ url }: WebViewNavigation) {
    if (url.includes('access_token')) {
      const [, search] = url.split('?');
      const urlParams = new URLSearchParams(search);
      const accessToken = urlParams.get('access_token')!;
      const refreshToken = urlParams.get('refresh_token')!;
      await initializeApplication({ token: accessToken, refreshToken });
      auth.refresh();
    }
  }

  useEffect(() => {
    if (!isNative) {
      redirect();
    }
  });

  return (
    <PageContainerAuth style={{ backgroundColor: '#f9fafb' }}>
      {isNative ? (
        <>
          <BackButton> Back </BackButton>
          <WebView
            onLoad={() => setLoadingWebview(false)}
            source={{
              uri:
                params.first_time == 'true'
                  ? config.authUrlNative + `&first_time=${params.first_time}`
                  : config.authUrlNative
            }}
            javaScriptEnabled
            style={{ backgroundColor: '#f9fafb' }}
            onNavigationStateChange={onNavigationChange}
            hideKeyboardAccessoryView
            originWhitelist={['*']}
          />
        </>
      ) : (
        <PageLoading />
      )}
    </PageContainerAuth>
  );
}
