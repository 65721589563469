import { useState } from 'react';
import {
  Platform,
  Image,
  View,
  Pressable,
  TextInput,
  KeyboardAvoidingView,
  ScrollView,
  ActivityIndicator
} from 'react-native';
import { Eye, EyeOff } from 'react-native-feather';
import api from '../../api';
import { useNavigator } from '../../compass/navigator';
import BackButton from '../../components/buttons/back-button';
import Button from '../../components/buttons/button';
import Input from '../../components/input';
import PageContainerAuth from '../../components/page-container/page-container-auth';
import {
  Heading1,
  Heading4,
  Heading5,
  Heading6,
  Paragraph2
} from '../../components/text-family';
import { Colors, Screen } from '../../constants';
import { breakpoint, buttonHorizontalWidth } from '../../helpers/breakpoint';
import { FetchError } from '../../helpers/fetcher';
import { RegisterBody } from '../../types';
export default function SignUp() {
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
  const navigator = useNavigator();
  const buttonWidth = buttonHorizontalWidth();
  const screenWidth = breakpoint();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const emailRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

  interface IRegisterBody extends RegisterBody {
    confirmPassword: string;
  }

  const [registerBody, setRegisterBody] = useState<IRegisterBody>({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  });

  function checkValidity(): boolean {
    return (
      registerBody.firstName !== undefined &&
      emailRegex.test(registerBody.username) &&
      registerBody.firstName.length >= 1 &&
      registerBody.lastName !== undefined &&
      registerBody.lastName.length >= 1
    );
  }

  async function handleRegister() {
    if (!checkValidity()) {
      return;
    }
    setLoading(true);
    const response = await api.postRegister(registerBody);

    if (response instanceof FetchError) {
      setLoading(false);
      return;
    }
    navigator.Go('Login', {
      first_time: true
    });
    setLoading(false);
  }

  return (
    <PageContainerAuth>
      <View style={{ flex: 1 }}>
        {isMobile ? <BackButton> Back </BackButton> : null}
        {!loading ? (
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 70 : 20}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <ScrollView style={{ flex: 1 }}>
              <View
                style={{
                  flex: 1,
                  paddingHorizontal: screenWidth == Screen.lg ? '30%' : 8,
                  width: '100%',
                  alignItems: 'center'
                }}
              >
                <Image
                  resizeMode="contain"
                  style={{
                    width: '70%',
                    height: 200,
                    marginTop: screenWidth == Screen.lg ? 40 : 0,
                    alignItems: 'center'
                  }}
                  source={{
                    uri: `https://assets.rivercitytech.cloud/nodes/1/enrolr/registration-fallback.png`
                  }}
                />
                <Heading1>Sign up</Heading1>
                <Heading5>Please enter your details to continue.</Heading5>
                <View
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    marginTop: 30
                  }}
                >
                  <View
                    style={{
                      width: '90%'
                    }}
                  >
                    <Input
                      title="Email"
                      required
                      keyboardType="email-address"
                      placeholder="Enter Email"
                      defaultValue={registerBody.username}
                      onChangeText={(text) => {
                        setRegisterBody({
                          ...registerBody,
                          username: text.trim().toLowerCase()
                        });
                      }}
                    />
                    {!emailRegex.test(registerBody.username) ? (
                      <Paragraph2 style={{ color: Colors.Danger }}>
                        Please enter a valid email!
                      </Paragraph2>
                    ) : null}
                    <Input
                      title="First Name"
                      required
                      placeholder="Enter First Name"
                      defaultValue={registerBody.firstName}
                      onChangeText={(text) => {
                        setRegisterBody({
                          ...registerBody,
                          firstName: text.trim()
                        });
                      }}
                    />
                    <Input
                      required
                      title="Last Name"
                      placeholder="Enter Last Name"
                      defaultValue={registerBody.lastName}
                      onChangeText={(text) => {
                        setRegisterBody({
                          ...registerBody,
                          lastName: text.trim()
                        });
                      }}
                    />

                    <View style={{ marginVertical: 4 }}>
                      <View style={{ flexDirection: 'row' }}>
                        <Heading6 style={{ marginBottom: 0, marginRight: 0 }}>
                          Password
                        </Heading6>
                        <Heading6 style={{ color: 'red', marginLeft: 2 }}>
                          *
                        </Heading6>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderColor: 'rgba(112, 112, 112, 1)',
                          borderWidth: 1,
                          borderRadius: 20,
                          backgroundColor: Colors.White,
                          margin: 4,
                          marginVertical: 4
                        }}
                      >
                        <TextInput
                          style={{
                            width: '90%',
                            borderRadius: 20,
                            paddingHorizontal: 16,
                            paddingVertical: 8
                          }}
                          secureTextEntry={!showPassword}
                          defaultValue={registerBody.password}
                          onChangeText={(text) => {
                            setRegisterBody({
                              ...registerBody,
                              password: text.trim()
                            });
                          }}
                          placeholder="Enter Password"
                          placeholderTextColor={Colors.Gray}
                        />
                        <Pressable
                          style={{ width: '10%', alignItems: 'center' }}
                          onPress={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <Eye width={18} stroke={Colors.Primary} />
                          ) : (
                            <EyeOff width={18} stroke={Colors.Primary} />
                          )}
                        </Pressable>
                      </View>
                    </View>

                    <View style={{ marginVertical: 4 }}>
                      <View style={{ flexDirection: 'row' }}>
                        <Heading6 style={{ marginBottom: 0, marginRight: 0 }}>
                          Confirm Password
                        </Heading6>
                        <Heading6 style={{ color: 'red', marginLeft: 2 }}>
                          *
                        </Heading6>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderColor: 'rgba(112, 112, 112, 1)',
                          borderWidth: 1,
                          borderRadius: 20,
                          backgroundColor: Colors.White,
                          margin: 4,
                          marginVertical: 4
                        }}
                      >
                        <TextInput
                          style={{
                            width: '90%',
                            borderRadius: 20,
                            paddingHorizontal: 16,
                            paddingVertical: 8
                          }}
                          onChangeText={(text) => {
                            setRegisterBody({
                              ...registerBody,
                              confirmPassword: text.trim()
                            });
                            if (registerBody.password !== text.trim()) {
                              setPasswordCheck(false);
                            } else {
                              setPasswordCheck(true);
                            }
                          }}
                          secureTextEntry={!showPassword}
                          placeholder="Enter Password"
                          placeholderTextColor={Colors.Gray}
                        />
                        <Pressable
                          style={{ width: '10%', alignItems: 'center' }}
                          onPress={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <Eye width={18} stroke={Colors.Primary} />
                          ) : (
                            <EyeOff width={18} stroke={Colors.Primary} />
                          )}
                        </Pressable>
                      </View>
                      {registerBody.password !==
                      registerBody.confirmPassword ? (
                        <Paragraph2 style={{ color: Colors.Danger }}>
                          Passwords do not match
                        </Paragraph2>
                      ) : null}
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      marginTop: 30,
                      marginBottom: 10
                    }}
                  >
                    <Button
                      type={
                        !passwordCheck ||
                        !checkValidity() ||
                        !emailRegex.test(registerBody.username)
                          ? 'disabled'
                          : 'primary'
                      }
                      style={{
                        width: screenWidth == Screen.lg ? '80%' : buttonWidth
                      }}
                      disabled={
                        !passwordCheck ||
                        !emailRegex.test(registerBody.username)
                      }
                      onPress={handleRegister}
                    >
                      Sign Up
                    </Button>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 12
                      }}
                    >
                      <Heading5
                        style={{ textAlign: 'center', alignContent: 'center' }}
                      >
                        Already have an account?
                      </Heading5>
                      <Pressable
                        onPress={() =>
                          navigator.Go('Login', {
                            first_time: false
                          })
                        }
                      >
                        <Heading4 style={{ color: Colors.Primary }}>
                          Login
                        </Heading4>
                      </Pressable>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        ) : (
          <ActivityIndicator
            size="large"
            color={Colors.Primary}
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
        )}
      </View>
    </PageContainerAuth>
  );
}
