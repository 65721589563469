import { useLayoutEffect, useState } from 'react';
import { Dimensions } from 'react-native';

window.addEventListener = (x: any) => x;
window.removeEventListener = (x: any) => x;

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([
        Dimensions.get('window').width,
        Dimensions.get('window').height
      ]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
