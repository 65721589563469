import React, { useState } from 'react';
import { TextInput, View } from 'react-native';
import { Colors } from '../../constants';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import { Paragraph2 } from '../text-family';

export default function NumberField({
  field,
  entry,
  displayFormError,
  onChange
}: FieldProps) {
  const [value, setValue] = useState(
    entry && (entry.data as { number: string }).number
      ? (entry.data as { number: string }).number.toString()
      : '0'
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!value?.length : true,
    message: 'This field is required'
  });

  function formatPhoneNumber(value: string) {
    if (!value) return value;
    return value.replace(/[^\d]/g, '');
  }

  function onChangeText(text: string) {
    const number = formatPhoneNumber(text);

    if (onChange) {
      onChange(
        { number: number ? +number : undefined },
        fieldIsValid(field, { number: number ? +number : undefined }).valid
      );
    }
    setError(fieldIsValid(field, { number: number ? +number : undefined }));
    setValue(number);
  }

  return (
    <View>
      <TextInput
        style={{
          borderColor:
            displayFormError || !error.valid
              ? 'red'
              : 'rgba(112, 112, 112, 0.5)',
          borderWidth: 1,
          borderRadius: 15,
          backgroundColor: Colors.White,
          margin: 4,
          marginVertical: 6,
          paddingHorizontal: 6,
          paddingVertical: 6
        }}
        value={value}
        onChangeText={onChangeText}
      />
      {field.mandatory ? (
        !error.valid ? (
          <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
            {error?.message}
          </Paragraph2>
        ) : null
      ) : null}
    </View>
  );
}
