import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import { useAuth } from '../../context/auth-context';
import initializeApplication from '../../helpers/initialize-application';

export default function Cb() {
  const [error, setError] = useState(false);
  const auth = useAuth();

  async function initializeApp() {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const refreshToken = urlParams.get('refresh_token');
    if (!accessToken || !refreshToken) {
      return setError(true);
    }
    await initializeApplication({ token: accessToken, refreshToken });
    auth.refresh();
  }

  useEffect(() => {
    initializeApp();
  }, []);

  return error ? <Loading /> : <Loading />;
}
