import {
  NativeSyntheticEvent,
  StyleProp,
  TargetedEvent,
  TextStyle
} from 'react-native';

interface Props {
  onValueChange?: (value: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
  selectedValue?: string;
  disabled?: boolean;
  items: { label: string; value: any }[];
  placeholder?: { label: string; value: any };
  style?: StyleProp<TextStyle> | undefined;
}

const { default: selectInferred } = require('./select');

export const Select: (props: Props) => JSX.Element = selectInferred;
