export enum Colors {
  Primary = '#15CEE6',
  ButtonPrimary = '#15CEE6',
  PrimaryLight = '#E3EFF5',
  PrimaryDark = '#000B15',
  OffWhite = 'rgba(0, 0, 0, 0.05)',
  Border = '#001529',
  White = 'white',
  Background = 'rgba(21,206,230, 0.1)',
  // ChatBackground = 'rgba(21,206,230, 0.5)',
  Danger = 'red',
  Secondary = '#C2CCCA',
  Black = 'black',
  Gray = 'rgba(112, 112, 112, 1)',
  GrayBorder = 'rgba(0, 0, 0, 0.1)'
}

export const PASSWORD_MIN_LENGTH = 8;
export const TENANT_SLUG_MIN_LENGTH = 4;
export const GENERIC_ERROR_MSG =
  "Please ensure you're connected to the internet and try again. If this problem persists, contact your administrator.";

export const MENU_FALLBACK_IMAGE_URL =
  'https://assets.rivercitytech.cloud/nodes/1/enrolr/menu-fallback.png';

export const REGISTRATION_FALLBACK_IMAGE_URL =
  'https://assets.rivercitytech.cloud/nodes/1/enrolr/registration-fallback.png';

export enum TenantUserRole {
  User = 'User',
  Administrator = 'Administrator',
  Supervisor = 'Supervisor'
}

export enum AttendanceType {
  BeforeSchool = 'Before School',
  AfterSchool = 'After School'
}

export enum ProgramFeeType {
  Annual = 'Annual',
  Monthly = 'Monthly',
  OneTime = 'One Time',
  Deferred = 'Deferred'
}

export enum ProgramTimeOfDay {
  AfterSchool = 'After School',
  BeforeSchool = 'Before School',
  Daytime = 'Daytime',
  Evening = 'Evening',
  Overnight = 'Overnight',
  Summer = 'Summer',
  Weekend = 'Weekend'
}

export enum programFeeRequired {
  Yes = 'Yes',
  No = 'No'
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other',
  NotSpecify = 'Unidentified'
}

export enum TypeOfIncident {
  AggressiveUnusualBehaviour = 'Aggressive/Unusual Behaviour',
  AttemptedSuicide = 'Attempted Suicide',
  Complaint = 'Complaint',
  DeathExpected = 'Death, expected',
  DeathUnexpected = 'Death, unexpected',
  DiseaseOutbreak = 'Disease Outbreak',
  DisruptionOfServices = 'Disruption of Services',
  DrugAlcoholDisposal = 'Drug/Alcohol Disposal',
  DrugAlcoholUse = 'Drug/Alcohol Use',
  EmergencyRestraint = 'Emergency Restraint',
  EmotionalAbuse = 'Emotional Abuse',
  Fall = 'Fall',
  FinancialAbuse = 'Financial Abuse',
  MedicalEmergency = 'Medical Emergency',
  MedicationError = 'Medication Error',
  MissingWandering = 'Missing/Wandering',
  MotorVehicleCrashWithInjury = 'Motor Vehicle Crash With Injury',
  MotorVehicleCrashWithoutInjury = 'Motor Vehicle Crash Without Injury',
  Neglect = 'Neglect',
  Other = 'Other',
  PhysicalAbuse = 'Physical Abuse',
  Poisoning = 'Poisoning',
  ServiceDeliveryProblems = 'Service Delivery Problems',
  SexualAbuse = 'Sexual Abuse',
  SuicidalIdeation = 'Suicidal Ideation',
  UnexpectedIllness = 'Unexpected Illness',
  VandalismPropertyDamage = 'Vandalism/Property Damage',
  WeaponsRelated = 'Weapons Related'
}

export enum ProgramActivities {
  Acting = 'Acting',
  Aerobics = 'Aerobics',
  Art = 'Art',
  Badminton = 'Badminton',
  Biking = 'Biking',
  Billiards = 'Billiards',
  Boxing = 'Boxing',
  Breakdancing = 'Breakdancing',
  CanoeingSailing = 'Canoeing/Sailing',
  Chess = 'Chess',
  Cooking = 'Cooking',
  Counselling = 'Counselling',
  Crafting = 'Crafting',
  DanceBallet = 'Dance - Ballet',
  DanceBelly = 'Dance - Belly',
  DanceHipHop = 'Dance - Hip Hop',
  DanceJazz = 'Dance - Jazz',
  DanceModern = 'Dance - Modern',
  DanceOther = 'Dance - Other',
  EmploymentSupport = 'Employment Support',
  FinanceBudget = 'Finance/Budget',
  Gardening = 'Gardening',
  Geocaching = 'Geocaching',
  Golf = 'Golf',
  Hiking = 'Hiking',
  HomeworkTutor = 'Homework/Tutor',
  HorsebackRiding = 'Horseback Riding',
  Kickboxing = 'Kickboxing',
  Leadership = 'Leadership',
  Lifeskills = 'Lifeskills',
  Literacy = 'Literacy',
  Music = 'Music',
  Numeracy = 'Numeracy',
  Parenting = 'Parenting',
  Pilates = 'Pilates',
  Pottery = 'Pottery',
  Reading = 'Reading',
  RockClimbing = 'Rock Climbing',
  Singing = 'Singing',
  Skiing = 'Skiing',
  SpendingTimeWithAnimals = 'Spending time with animals',
  Swimming = 'Swimming',
  Baseball = 'Baseball',
  Basketball = 'Basketball',
  Football = 'Football',
  Hockey = 'Hockey',
  Other = 'Other',
  Soccer = 'Soccer',
  Volleyball = 'Volleyball',
  Walking = 'Walking',
  Wilderness = 'Wilderness',
  Writing = 'Writing',
  Yoga = 'Yoga'
}

export enum DataFieldType {
  Text = 'text',
  Select = 'select',
  Number = 'number',
  Date = 'date',
  Waiver = 'waiver',
  TextArea = 'textArea',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode'
}

export enum DataFieldTarget {
  Child = 'child',
  User = 'user'
}

export enum ProgramCapacityRequired {
  No = 'No',
  Yes = 'Yes'
}

export enum PaymentType {
  Subscription = 'subscription',
  OneTime = 'one-time',
  NoPayment = 'no-payment',
  Deferred = 'deferred'
}

export enum PaymentStatus {
  Succeeded = 'succeeded',
  Failed = 'failed',
  Pending = 'pending',
  NotAssociated = 'not-associated',
  Deferred = 'deferred'
}

export enum ServerStatusState {
  Unchanged,
  Loading,
  Error,
  Success
}

export enum ReportType {
  Parent = 'Parent',
  Child = 'Child'
}

export enum PaymentMethodType {
  PreAuthorizedDebit = 'preAuthorizedDebit',
  CreditCard = 'creditCard'
}

export enum InvoiceStatusFilterType {
  Success = 'success',
  Pending = 'pending',
  Failed = 'failed'
}

export enum ActivityType {
  UserInteractWithApi = 'user-interact-with-api'
}

export enum Screen {
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export enum ScreenWidth {
  Mobile = 768
}

export enum PaymentAdapter {
  Stripe = 'stripe'
}

export enum EnrolmentRequirementType {
  AddPaymentMethod = 'add_payment_method',
  Pay = 'pay',
  Subscribe = 'subscribe',
  Nothing = 'nothing'
}
