import React from 'react';
import { TextInputProps, View, TextInput } from 'react-native';
import { Colors } from '../constants';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';
import { Heading6 } from './text-family';

interface Props extends TextInputProps {
  title?: string;
  required?: boolean;
}
export default function Input(props: Props) {
  return (
    <View style={{ marginVertical: 4 }}>
      {props.title?.length ? (
        <View style={{ flexDirection: 'row' }}>
          <Heading6 style={{ marginBottom: 0, marginRight: 0 }}>
            {props.title}
          </Heading6>
          {props.required ? (
            <Heading6 style={{ color: 'red', marginBottom: 0, marginLeft: 2 }}>
              *
            </Heading6>
          ) : null}
        </View>
      ) : null}
      <TextInput
        placeholderTextColor={Colors.Gray}
        {...composePropsWithDefaultStyles(
          {
            borderColor: 'rgba(112, 112, 112, 0.5)',
            borderWidth: 1,
            borderRadius: 20,
            backgroundColor: Colors.White,
            margin: 4,
            marginVertical: 4,
            paddingHorizontal: 16,
            paddingVertical: 8,
            color: 'rgba(112, 112, 112, 1)',
            fontFamily: 'Poppins-Regular'
          },
          props as any
        )}
      />
    </View>
  );
}
