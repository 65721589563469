import { toast } from 'react-hot-toast';

export default function useNotify() {
  return {
    alert(text: string) {
      window.alert(text);
    },
    success(text: string, timeout = 5000) {
      toast.success(text, {
        style: { fontFamily: 'Poppins-Regular' },
        position: 'top-center',
        duration: timeout
      });
    },
    error(text: string, timeout = 5000) {
      toast.error(text, {
        style: { fontFamily: 'Poppins-Regular' },
        position: 'top-center',
        duration: timeout
      });
    }
  };
}
