import React, { useState } from 'react';
import { View } from 'react-native';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import { DatePicker } from '../date-picker';
import { Paragraph2 } from '../text-family';
export default function DateField({
  field,
  entry,
  onChange,
  displayFormError
}: FieldProps) {
  const [value, setValue] = useState(
    entry && (entry.data as { date: number }).date
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!value : true,
    message: 'This field is required'
  });
  return (
    <View>
      <DatePicker
        error={displayFormError || !error.valid}
        onChange={(date) => {
          setValue(date);
          if (onChange) {
            onChange({ date }, fieldIsValid(field, { date }).valid);
          }
          setError(fieldIsValid(field, { date }));
        }}
        value={entry && (entry.data as { date: number }).date}
      />
      {field.mandatory ? (
        !error.valid ? (
          <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
            {error?.message}
          </Paragraph2>
        ) : null
      ) : null}
    </View>
  );
}
