import React from 'react';
import { View } from 'react-native';
import { Check, Smile } from 'react-native-feather';
import { Child } from '../../types';
import { Heading6 } from '../text-family';

interface Props {
  child: Child;
  selected?: boolean;
}
export default function ChildSelectItem({ child, selected }: Props) {
  return (
    <View style={{ width: '100%', justifyContent: 'center' }}>
      <View
        style={{
          backgroundColor: 'rgba(6,255,255,0.5)',
          width: 64,
          height: 64,
          borderRadius: 50,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {selected === true ? (
          <Check color={'black'} width={48} height={48} />
        ) : (
          <Smile color={'#00C7C7'} width={40} height={40} />
        )}
      </View>
      <Heading6 style={{}}>
        {child.lastName}, {child.firstName}
      </Heading6>
    </View>
  );
}
