import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import ContentContainer from '../../../components/content-container';
import Loading from '../../../components/loading';
import FuturePayment from '../../../components/program/future-payment';
import Pay from '../../../components/program/pay';
import Subscribe from '../../../components/program/subscribe';
import { Heading1, Paragraph1 } from '../../../components/text-family';
import { Colors, EnrolmentRequirementType } from '../../../constants';
import {
  usePreenrolmentEnrolment,
  usePreenrolmentEnrolmentRequirements
} from '../../../hooks';
import {
  Program,
  Child,
  Preenrolment,
  EnrolmentRequirement
} from '../../../types';

export default function Registration() {
  const navigator = useNavigator();
  const {
    program,
    children,
    preenrolment,
    enrolmentRequirementSuccess
  }: {
    enrolmentRequirementSuccess?: string;
    program: Program;
    children: Child[];
    preenrolment: Preenrolment;
    enrolmentRequirements: EnrolmentRequirement[];
  } = navigator.Params();
  const [currentRequirementIndex, setCurrentRequirementIndex] = useState(0);
  const {
    data: enrolmentRequirementsRaw,
    loading: enrolmentRequirementsLoading
  } = usePreenrolmentEnrolmentRequirements(preenrolment.id);
  const currentEnrolmentRequirements = useMemo(() => {
    if (!enrolmentRequirementsRaw) {
      return [];
    }
    return enrolmentRequirementsRaw
      .filter(
        (er) =>
          er.fulfilled === false &&
          !er.pending &&
          er.id !==
            (enrolmentRequirementSuccess ? +enrolmentRequirementSuccess : -1)
      )
      .sort((a, b) => a.id - b.id);
  }, [enrolmentRequirementsRaw]);
  const currentEnrolmentRequirement = useMemo(() => {
    return currentEnrolmentRequirements[currentRequirementIndex];
  }, [currentEnrolmentRequirements, currentRequirementIndex]);

  const { data: enrolment, loading: enrolmentLoading } =
    usePreenrolmentEnrolment(preenrolment.id);

  function next() {
    setCurrentRequirementIndex(currentRequirementIndex + 1);
  }

  if (
    !enrolmentRequirementsRaw ||
    enrolmentRequirementsLoading ||
    enrolmentLoading
  ) {
    return <Loading />;
  }

  if (!currentEnrolmentRequirements.length && !enrolment) {
    return (
      <View style={{ flex: 1, backgroundColor: Colors.Background }}>
        <ContentContainer>
          <Card>
            <Heading1>Your registration in this program is pending.</Heading1>
            <Paragraph1>
              This could mean that your payment has not yet finished processing.
              Please check back later.
            </Paragraph1>
          </Card>
          <Button
            style={{ marginTop: 24 }}
            type="primary"
            onPress={() => navigator.Go('Programs')}
          >
            Back to program listings
          </Button>
        </ContentContainer>
      </View>
    );
  }

  if (enrolment) {
    return (
      <View style={{ flex: 1, backgroundColor: Colors.Background }}>
        <ContentContainer>
          <Card>
            <Heading1 style={{ color: 'green' }}>Success!</Heading1>
            <Paragraph1>
              You have successfully registered for this program. You should
              shortly receive an email with more information.
            </Paragraph1>
          </Card>
          <Button
            style={{ marginTop: 24 }}
            type="primary"
            onPress={() => navigator.Go('Programs')}
          >
            Back to program listings
          </Button>
        </ContentContainer>
      </View>
    );
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.Background }}>
      <ContentContainer>
        {(() => {
          switch (currentEnrolmentRequirement.type) {
            case EnrolmentRequirementType.Subscribe:
              return (
                <Subscribe
                  steps={enrolmentRequirementsRaw.length}
                  program={program}
                  children={children}
                  onFinish={next}
                  enrolmentRequirementId={currentEnrolmentRequirement.id}
                />
              );
            case EnrolmentRequirementType.AddPaymentMethod:
              return (
                <FuturePayment
                  steps={enrolmentRequirementsRaw.length}
                  program={program}
                  children={children}
                  onFinish={next}
                  enrolmentRequirementId={currentEnrolmentRequirement.id}
                />
              );
            case EnrolmentRequirementType.Pay:
              return (
                <Pay
                  steps={enrolmentRequirementsRaw.length}
                  program={program}
                  children={children}
                  onFinish={next}
                  enrolmentRequirementId={currentEnrolmentRequirement.id}
                />
              );
          }
        })()}
      </ContentContainer>
    </View>
  );
}
