export type EventSubscriber = () => unknown;

class _EventHolder {
  public static subscribers: Record<string, EventSubscriber[]> = {};
}

export async function emit(topic: string) {
  for (const subscriber of _EventHolder.subscribers[topic] ?? []) {
    subscriber();
  }
}

export function subscribe(topic: string, cb: EventSubscriber) {
  const existing = _EventHolder.subscribers[topic] ?? [];
  _EventHolder.subscribers[topic] = [cb, ...existing];
}

export function unsubscribe(topic: string, cb: EventSubscriber) {
  _EventHolder.subscribers[topic] = (
    _EventHolder.subscribers[topic] ?? []
  ).filter((s) => s !== cb);
}
