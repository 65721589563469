import { Platform } from 'react-native';
import { Config } from './config.common';

const config: Config = {
  authUrlNative:
    Platform.OS === 'ios' || Platform.OS === 'android'
      ? 'https://auth-staging.enrolr.com/authorize?redirect_uri=https://auth.enrolr.com/_implicit'
      : 'https://auth-staging.enrolr.com',
  authUrlWeb:
    'https://auth.enrolr.com/authorize?redirect_uri=https://app2-staging.enrolr.com/cb',
  backendUrl: 'https://api-staging.enrolr.com',
  environment: 'staging'
};
export default config;
