import React, { FunctionComponent, useState } from 'react';
import { View, Text, Dimensions } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colors } from '../../constants';
import HoverableOpacity from '../buttons/hoverable-opacity';

interface Props {
  currentTab?: boolean;
  icon: FunctionComponent<SvgProps>;
  text?: string;
  onClick: () => void;
}

export default function NavigationButton(props: Props) {
  const [hovering, setHovering] = useState(false);
  const { width: screenWidth } = Dimensions.get('window');
  const iconSize = screenWidth / 110;
  const fontSize = screenWidth / 110;
  const Icon = props.icon;

  return (
    <HoverableOpacity
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={
        props.currentTab === true
          ? {
              backgroundColor: Colors.GrayBorder,
              borderBottomColor: Colors.GrayBorder,
              borderBottomWidth: 1,
              borderLeftColor: Colors.ButtonPrimary,
              borderLeftWidth: 5,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: 8
            }
          : {
              borderBottomColor: Colors.GrayBorder,
              borderBottomWidth: 1,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: 8
            }
      }
      onPress={props.onClick}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <View
          style={{
            width: '20%',
            paddingRight: 15,
            alignItems: 'center'
          }}
        >
          <Icon
            width={iconSize}
            height={iconSize}
            color={
              hovering || props.currentTab === true
                ? Colors.ButtonPrimary
                : Colors.Secondary
            }
          />
        </View>
        <View
          style={{
            width: props.currentTab === true ? '50%' : '45%'
          }}
        >
          <Text
            style={{
              justifyContent: 'center',
              alignContent: 'center',
              fontSize: fontSize,
              fontFamily: 'Poppins-Regular',
              color:
                hovering || props.currentTab === true
                  ? Colors.ButtonPrimary
                  : Colors.Secondary
            }}
          >
            {props.text}
          </Text>
        </View>
      </View>
    </HoverableOpacity>
  );
}
