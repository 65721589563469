import { View } from 'react-native';
import { Paragraph2 } from './text-family';

enum LabelColors {
  DangerBackground = 'rgba(244, 67, 54, 0.2)',
  DangerText = 'rgba(244, 67, 54, 1)',
  SuccessBackground = 'rgba(0,128,0, 0.1)',
  SuccessText = 'rgba(0,128,0, 1)',
  WarningBackground = 'rgba(255, 193, 7, 0.2)',
  WarningText = 'rgba(255, 193, 7, 1)'
}

interface Props {
  type: 'primary' | 'secondary' | 'success' | 'warning' | 'danger';
  children: string;
}

export default function Label(props: Props) {
  return (
    <View
      style={{
        backgroundColor:
          props.type == 'warning'
            ? LabelColors.WarningBackground
            : props.type == 'success'
            ? LabelColors.SuccessBackground
            : LabelColors.DangerBackground,
        paddingHorizontal: 6,
        paddingVertical: 1,
        borderRadius: 20,
        borderColor:
          props.type == 'warning'
            ? LabelColors.WarningText
            : props.type == 'success'
            ? LabelColors.SuccessText
            : LabelColors.DangerText,
        borderWidth: 1,
        flexDirection: 'row',
        alignSelf: 'flex-start'
      }}
    >
      <Paragraph2
        style={{
          color:
            props.type == 'warning'
              ? LabelColors.WarningText
              : props.type == 'success'
              ? LabelColors.SuccessText
              : LabelColors.DangerText,
          fontSize: 10,
          margin: 0
        }}
      >
        {props.children}
      </Paragraph2>
    </View>
  );
}
