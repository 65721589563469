import { SafeAreaView, View } from 'react-native';
import { useEnrolmentRequirementFutureCheckoutUrl } from '../../../hooks';
import { Child, Program } from '../../../types';
import Button from '../../buttons/button';
import Loading from '../../loading';
import { Heading1, Heading3 } from '../../text-family';

interface Props {
  steps: number;
  program: Program;
  children: Child[];
  enrolmentRequirementId: number;
  onFinish(): unknown;
}

export default function FuturePayment({
  steps,
  enrolmentRequirementId
}: Props) {
  const { data: url } = useEnrolmentRequirementFutureCheckoutUrl(
    window.location.href +
      `&enrolmentRequirementSuccess=${enrolmentRequirementId}`,
    window.location.href +
      `&enrolmentRequirementFailure=${enrolmentRequirementId}`,
    enrolmentRequirementId
  );

  return (
    <SafeAreaView>
      <View
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingHorizontal: 32,
          paddingVertical: 16
        }}
      >
        {url ? (
          <>
            <Heading1>Step 1 of {steps}</Heading1>
            <Heading3>
              You need to link a payment method so that we can invoice you for
              use in this program.
            </Heading3>
            <Button
              type="primary"
              onPress={() => (window.location.href = url)}
              style={{ marginTop: 16 }}
            >
              Continue
            </Button>
          </>
        ) : (
          <Loading />
        )}
      </View>
    </SafeAreaView>
  );
}
