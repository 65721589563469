import { Dimensions } from 'react-native';
import { ScreenWidth } from '../../constants';
import PageContainerDesk from './page-container-desk';
const PageContainerMobile =
  require('./page-container-mobile/page-container-mobile').default;

const PageContainer =
  Dimensions.get('screen').width <= ScreenWidth.Mobile
    ? PageContainerMobile
    : PageContainerDesk;

export default PageContainer;
