import React from 'react';
import { View } from 'react-native';
import Loading from '../../../components/loading';
import ProgramList from '../../../components/program/program-list';
import useTenantId from '../../../hooks/use-tenant-id';
import { useLiveProgramsWithMeta } from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';

export default function Programs() {
  const tenantId = useTenantId();
  const meId = useMeId();
  const { data: programs, loading } = useLiveProgramsWithMeta(tenantId, meId);
  return (
    <View style={{ flex: 1 }}>
      {!loading && tenantId ? <ProgramList programs={programs} /> : <Loading />}
    </View>
  );
}
