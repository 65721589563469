export async function userId(): Promise<number | undefined> {
  const userId = localStorage.getItem('userId');
  return userId ? +userId : undefined;
}

export async function tenantId(): Promise<number | undefined> {
  const tenantId = localStorage.getItem('tenantId');
  return tenantId ? +tenantId : undefined;
}

import { Storage } from '.';
import { emit } from '../events/event';

const webStorage: Storage = {
  getItem: function (key: string): Promise<string | null> {
    return Promise.resolve(window.localStorage.getItem(key));
  },
  setItem: async function (key: string, value: string): Promise<void> {
    window.localStorage.setItem(key, value);
    await emit('storage-changed');
    return Promise.resolve();
  },
  clear: async function (): Promise<void> {
    window.localStorage.clear();
    emit('storage-changed');
    return Promise.resolve();
  }
};
export default webStorage;
