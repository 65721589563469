import React from 'react';
import { Dimensions, View } from 'react-native';
import { useNavigator } from '../../../compass/navigator';
import Loading from '../../../components/loading';
import ProgramDescription from '../../../components/program/program-description';
import { Colors } from '../../../constants';
import {
  useChildren,
  useNumberOfRegisteredChildren,
  useProgram
} from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';
// import useNumberOfRegisteredChildren from '../../../hooks/use-program-number-of-registered-children';

export default function Program() {
  const navigator = useNavigator();
  const params = navigator.Params();
  const meId = useMeId();
  const { data: program } = useProgram(params.programId);
  const { data: children } = useChildren(meId);
  const { data: numberOfRegistered } = useNumberOfRegisteredChildren(
    params.programId
  );
  const isMobile = Dimensions.get('window').width < 768;

  return (
    <View style={{ flex: 1 }}>
      {!isMobile && (
        <View
          style={{
            backgroundColor: Colors.Primary,
            position: 'absolute',
            width: '90%',
            height: '33%',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            alignSelf: 'center'
          }}
        />
      )}
      {program && children ? (
        <ProgramDescription
          program={program}
          children={children}
          numberRegistered={numberOfRegistered}
        />
      ) : (
        <Loading />
      )}
    </View>
  );
}
