import { View } from 'native-base';
import React, { ReactNode } from 'react';
import {
  ActivityIndicator,
  TouchableOpacity,
  TouchableOpacityProps
} from 'react-native';
import { Colors } from '../../constants';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';
import { Heading5 } from '../text-family';

interface Props extends TouchableOpacityProps {
  textColor?: string;
  buttonColor?: string;
  icon?: ReactNode;
  children?: string | string[];
  loading?: boolean;
  type?: 'primary' | 'secondary' | 'danger' | 'outline' | 'disabled' | 'white';
}

export default function Button(props: Props) {
  const theProps = props.loading ? { ...props, onPress: undefined } : props;
  return theProps.type === 'primary' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: Colors.ButtonPrimary,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.White,
          fontWeight: '400',
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  ) : theProps.type === 'secondary' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          borderWidth: 1,
          borderColor: Colors.Secondary,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.Secondary,
          fontWeight: '400',
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  ) : theProps.type === 'danger' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          borderWidth: 1,
          borderColor: Colors.Danger,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 5,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.Danger,
          fontWeight: '400',
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  ) : theProps.type === 'outline' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          borderWidth: 1,
          borderColor: Colors.Primary,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.Primary,
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  ) : theProps.type === 'disabled' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: 'rgb(210, 210, 210)',
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.Gray,
          fontWeight: '400',
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  ) : theProps.type === 'white' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: Colors.White,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : null}
        <Heading5
          style={{
            textAlign: 'center',
            color: Colors.Black,
            fontWeight: '400',
            margin: 0,
            marginLeft: theProps.loading || theProps.icon ? 10 : 0
          }}
        >
          {theProps.children}
        </Heading5>
      </View>
    </TouchableOpacity>
  ) : (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: Colors.ButtonPrimary,
          marginVertical: 5,
          borderRadius: 20,
          paddingVertical: 6,
          paddingHorizontal: 12,
          width: '100%'
        },
        theProps as any
      )}
    >
      <Heading5
        style={{
          textAlign: 'center',
          color: Colors.Black,
          fontWeight: '600',
          margin: 0
        }}
      >
        {theProps.loading ? (
          <ActivityIndicator />
        ) : theProps.icon ? (
          theProps.icon
        ) : (
          theProps.children
        )}
      </Heading5>
    </TouchableOpacity>
  );
}
