import React from 'react';
import { Compass } from './compass/compass';
import { Navigator } from './compass/navigator';
import Screen from './compass/screen';
import Loading from './components/loading';
const { default: PageContainer } = require('./components/page-container');
import { useAuth } from './context/auth-context';
import Billing from './screens/app/billing/billing';
import AddChild from './screens/app/children/add-child';
import Children from './screens/app/children/children';
import Home from './screens/app/home/home';
import Logout from './screens/app/logout';
import Chat from './screens/app/notifications/chat';
import Notifications from './screens/app/notifications/notifications';
import Profile from './screens/app/profile/profile';
import ProfileDetails from './screens/app/profile/profile-details';
import Program from './screens/app/programs/program';
import ProgramDetails from './screens/app/programs/program-details';
import Programs from './screens/app/programs/programs';
import Registration from './screens/app/programs/registration';
import Cb from './screens/auth/cb';
import Login from './screens/auth/login';
import SignUp from './screens/auth/signup';
import Welcome from './screens/auth/welcome';
import ChooseTenant from './screens/app/tenant/choose-tenant';
import TenantRequirements from './screens/app/tenant/tenant-requirements';
import ChildDetails from './components/child/child-details';
import ChildEdit from './screens/app/children/child';
import Receipt from './screens/app/billing/receipt';

export default function Router() {
  const { token, tenantId, loaded } = useAuth();

  if (!loaded) {
    return <Loading />;
  }

  const chooseTenantAppLinking = {
    prefixes: [],
    config: {
      screens: {
        TenantRequirements: {
          screens: {
            ChooseTenant: '/choose-tenant',
            TenantFields: '/tenant-requirements'
          }
        }
      }
    }
  };

  const mainAppLinking = {
    prefixes: [],
    config: {
      screens: {
        App: {
          screens: {
            HomeTab: { screens: { Home: '/home' } },
            ProfileTab: {
              screens: {
                Profile: '/profile',
                ProfileDetails: '/profile/details'
              }
            },
            ProgramsTab: {
              screens: {
                Programs: '/programs',
                Program: '/programs/program',
                ProgramDetails: '/programs/program/details',
                Registration: '/programs/program/registration'
              }
            },
            BillingTab: {
              screens: { Billing: '/billing', Receipt: '/billing/receipts' }
            },
            ChildrenTab: {
              screens: {
                Children: '/children',
                ChildDetails: '/children/child-details',
                ChildEdit: '/children/child-edit',
                AddChild: '/children/add-child'
              }
            },
            // NotificationsTab: {
            //   screens: {
            //     Notifications: '/notifications',
            //     Chat: '/notifications/chat'
            //   }
            // },
            Logout: '/logout'
          }
        }
      }
    }
  };

  const authLinking = {
    prefixes: [],
    config: {
      screens: {
        Auth: {
          screens: {
            Welcome: '/welcome',
            Login: '/login',
            SignUp: '/signup',
            Cb: '/cb'
          }
        }
      }
    }
  };

  return !token ? (
    <Compass linking={authLinking} defaultPath="/welcome">
      <Navigator name="Auth" path="/">
        <Screen name="Welcome" path="/welcome">
          <Welcome />
        </Screen>
        <Screen name="Login" path="/login">
          <Login />
        </Screen>
        <Screen name="SignUp" path="/signup">
          <SignUp />
        </Screen>
        <Screen name="Cb" path="/cb">
          <Cb />
        </Screen>
      </Navigator>
    </Compass>
  ) : (
    <Compass
      linking={tenantId ? mainAppLinking : chooseTenantAppLinking}
      defaultPath={tenantId ? '/home' : '/choose-tenant'}
    >
      {!tenantId ? (
        <Navigator
          path="/choose-tenant"
          name="TenantRequirements"
          key="choose-tenant"
        >
          <Screen name="ChooseTenant" path="/choose-tenant">
            <ChooseTenant />
          </Screen>
          <Screen name="TenantFields" path="/tenant-requirements">
            <TenantRequirements />
          </Screen>
        </Navigator>
      ) : (
        <Navigator path="/" name="App" navigation={PageContainer} key="main">
          <Navigator name="HomeTab" key="HomeTab" path="/home">
            <Screen name="Home" path="/home">
              <Home />
            </Screen>
          </Navigator>

          <Navigator name="ProfileTab" key="ProfileTab" path="/profile">
            <Screen name="Profile" path="/profile">
              <Profile />
            </Screen>
            <Screen name="ProfileDetails" path="/profile/details">
              <ProfileDetails />
            </Screen>
          </Navigator>

          <Navigator name="ProgramsTab" key="ProgramsTab" path="/programs">
            <Screen name="Programs" path="/programs">
              <Programs />
            </Screen>
            <Screen name="Program" path="/programs/program">
              <Program />
            </Screen>
            <Screen name="ProgramDetails" path="/programs/program/details">
              <ProgramDetails />
            </Screen>
            <Screen name="Registration" path="/programs/program/registration">
              <Registration />
            </Screen>
          </Navigator>

          <Navigator name="BillingTab" key="BillingTab" path="/billing">
            <Screen name="Billing" path="/billing">
              <Billing />
            </Screen>
            <Screen name="Receipt" path="/billing/receipts">
              <Receipt />
            </Screen>
          </Navigator>

          <Navigator name="ChildrenTab" key="ChildrenTab" path="/children">
            <Screen name="Children" path="/children">
              <Children />
            </Screen>
            <Screen name="ChildDetails" path="/children/child-details">
              <ChildDetails />
            </Screen>
            <Screen name="ChildEdit" path="/children/child-edit">
              <ChildEdit />
            </Screen>
            <Screen name="AddChild" path="/children/add-child">
              <AddChild />
            </Screen>
          </Navigator>

          {/* <Navigator
            name="NotificationsTab"
            key="NotificationsTab"
            path="/notifications"
          >
            <Screen name="Notifications" path="/notifications">
              <Notifications />
            </Screen>
            <Screen name="Chat" path="/notifications/chat">
              <Chat />
            </Screen>
          </Navigator> */}

          <Screen name="Logout" path="/logout">
            <Logout />
          </Screen>
        </Navigator>
      )}
    </Compass>
  );
}
