import { useMemo, useState } from 'react';
import {
  Image,
  ImageBackground,
  ImageStyle,
  StyleProp,
  ViewStyle
} from 'react-native';
import useTenantId from '../../hooks/use-tenant-id';
import Loading from '../loading';

interface Props {
  programId: number;
  style: StyleProp<ImageStyle>;
  type?: 'background' | 'main';
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export default function ProgramImage(props: Props) {
  const tenantId = useTenantId();
  const [imageUri, setImageUri] = useState<string>('');

  useMemo(() => {
    if (tenantId) {
      setImageUri(
        `https://assets.rivercitytech.cloud/nodes/1/enrolr/tenants/2/programs/${props.programId}/main.img`
      );
    }
  }, [tenantId]);

  function imageError() {
    setImageUri(
      'https://assets.rivercitytech.cloud/nodes/1/generic/placeholder.img'
    );
  }

  return tenantId ? (
    props.type == 'background' ? (
      <ImageBackground
        source={{
          uri: imageUri
        }}
        resizeMode="cover"
        imageStyle={props.style}
        style={props.containerStyle}
        onError={() => imageError()}
      >
        {props.children}
      </ImageBackground>
    ) : (
      <Image
        source={{
          uri: imageUri
        }}
        style={props.style}
        onError={() => imageError()}
      />
    )
  ) : (
    <Loading />
  );
}
