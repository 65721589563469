import { useState, useEffect } from 'react';
import api, { apiResult } from '../api';
import { User } from '../types';
import { FetchError } from '../helpers/fetcher';

export default function useMe() {
  const [user, setUser] = useState<User>();

  async function getUser() {
    const result = apiResult(await api.getMe());

    if (result instanceof FetchError) {
      throw result;
    }
    setUser(result.me);
  }

  useEffect(() => {
    getUser();
  }, []);

  return user;
}
