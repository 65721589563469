import api from './api';
import { FetchError } from './helpers/fetcher';
import use from './helpers/use-api-result';

async function wrapApiFetch<K extends string, T>(
  cb: Promise<Record<K, T> | FetchError<unknown>>,
  key: K
): Promise<T>;
async function wrapApiFetch<T>(
  cb: Promise<T | FetchError<unknown>>
): Promise<T>;
async function wrapApiFetch<K extends string, T>(
  cb: Promise<(T | Record<K, T>) | FetchError<unknown>>,
  key?: K
): Promise<T> {
  const result = await cb;
  if (result instanceof FetchError) {
    throw result;
  }
  return key ? (result as Record<K, T>)[key] : (result as T);
}

export function useMe() {
  return use(() => wrapApiFetch(api.getMe(), 'me'), []);
}

export function usePublicTenants() {
  return use(() => wrapApiFetch(api.getAllPublicTenants(), 'tenants'), []);
}

export function useTenantSlug(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getTenantSlug(tenantId!), 'slug'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useUserTenants(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getUserTenant(tenantId!), 'tenants'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useAdmins(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getAdmins(tenantId!), 'admins'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useMessageConversations(userId?: number) {
  return use(
    () => wrapApiFetch(api.getMessageConversations(userId!), 'conversations'),
    [userId],
    {
      waitUntil: [userId]
    }
  );
}

export function useMessageConversation(conversationId?: number) {
  return use(
    () =>
      wrapApiFetch(api.getMessageConversation(conversationId!), 'conversation'),
    [conversationId],
    {
      waitUntil: [conversationId]
    }
  );
}

export function useMessages(conversationId?: number) {
  return use(
    () => wrapApiFetch(api.getMessages(conversationId!), 'messages'),
    [conversationId],
    {
      waitUntil: [conversationId]
    }
  );
}

export function useChild(childId?: number) {
  return use(() => wrapApiFetch(api.getChild(childId!), 'child'), [childId], {
    waitUntil: [childId]
  });
}

export function useChildren(parentId?: number) {
  return use(
    () => wrapApiFetch(api.getChildren(parentId!), 'children'),
    [parentId],
    {
      waitUntil: [parentId]
    }
  );
}

export function useTenantFields(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getTenantFields(tenantId!), 'fields'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}
export function useChildrenTenantFields(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getChildrenTenantFields(tenantId!), 'fields'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}
export function useUserTenantFields(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getUserTenantFields(tenantId!), 'fields'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useUserTenantEntries(userId?: number, tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getUserTenantEntries(userId!, tenantId!), 'fields'),
    [userId, tenantId],
    {
      waitUntil: [userId, tenantId]
    }
  );
}

export function useChildTenantEntries(childId?: number, tenantId?: number) {
  return use(
    () =>
      wrapApiFetch(api.getChildTenantEntries(childId!, tenantId!), 'fields'),
    [childId, tenantId],
    {
      waitUntil: [childId, tenantId]
    }
  );
}

export function useTenantProgramSites(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getTenantProgramSites(tenantId!), 'programSites'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useTenantProgramTypes(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getTenantProgramTypes(tenantId!), 'programTypes'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useLiveProgramsWithMeta(tenantId?: number, userId?: number) {
  return use(
    () =>
      wrapApiFetch(
        api.getLiveProgramsWithMeta(tenantId!, userId!),
        'liveProgramsWithMeta'
      ),
    [tenantId, userId],
    {
      waitUntil: [tenantId, userId]
    }
  );
}

export function useProgram(programId?: number) {
  return use(
    () => wrapApiFetch(api.getProgram(programId!), 'program'),
    [programId],
    {
      waitUntil: [programId]
    }
  );
}

export function usePrograms(tenantId?: number) {
  return use(
    () => wrapApiFetch(api.getPrograms(tenantId!), 'programs'),
    [tenantId],
    {
      waitUntil: [tenantId]
    }
  );
}

export function useProgramFields(programId?: number) {
  return use(
    () => wrapApiFetch(api.getProgramFields(programId!), 'fields'),
    []
  );
}

export function useNumberOfRegisteredChildren(programId?: number) {
  return use(
    () =>
      wrapApiFetch(
        api.getNumberOfRegisteredChildren(programId!),
        'numberOfRegisteredChildren'
      ),
    [programId],
    {
      waitUntil: [programId]
    }
  );
}

export function usePreenrolmentEnrolment(preenrolmentId?: number) {
  return use(
    () =>
      wrapApiFetch(api.getPreenrolmentEnrolment(preenrolmentId!), 'enrolment'),
    [preenrolmentId],
    { waitUntil: [preenrolmentId] }
  );
}

export function useEnrolmentRequirementCheckoutUrl(
  hrefSuccess: string,
  hrefCancel: string,
  enrolmentRequirementId?: number
) {
  return use(
    () =>
      wrapApiFetch(
        api.stripeCheckout(enrolmentRequirementId!, hrefSuccess, hrefCancel),
        'url'
      ),
    [enrolmentRequirementId],
    { waitUntil: [enrolmentRequirementId] }
  );
}

export function useEnrolmentRequirementSubscriptionCheckoutUrl(
  hrefSuccess: string,
  hrefCancel: string,
  enrolmentRequirementId?: number
) {
  return use(
    () =>
      wrapApiFetch(
        api.stripeSubscriptionCheckout(
          enrolmentRequirementId!,
          hrefSuccess,
          hrefCancel
        ),
        'url'
      ),
    [enrolmentRequirementId],
    { waitUntil: [enrolmentRequirementId] }
  );
}

export function useEnrolmentRequirementFutureCheckoutUrl(
  hrefSuccess: string,
  hrefCancel: string,
  enrolmentRequirementId?: number
) {
  return use(
    () =>
      wrapApiFetch(
        api.stripeFutureCheckout(
          enrolmentRequirementId!,
          hrefSuccess,
          hrefCancel
        ),
        'url'
      ),
    [enrolmentRequirementId],
    { waitUntil: [enrolmentRequirementId] }
  );
}

export function usePreenrolmentEnrolmentRequirements(preenrolmentId?: number) {
  return use(
    () =>
      wrapApiFetch(
        api.getPreenrolmentEnrolmentRequirements(preenrolmentId!),
        'enrolmentRequirements'
      ),
    [preenrolmentId],
    { waitUntil: [preenrolmentId] }
  );
}

export function useProgramUserPreenrolments(
  programId?: number,
  userId?: number
) {
  return use(
    () =>
      wrapApiFetch(
        api.getProgramUserPreenrolments(programId!, userId!),
        'preenrolments'
      ),
    [programId, userId],
    { waitUntil: [programId, userId] }
  );
}

export function useUserPurchases(userId?: number) {
  return use(
    () => wrapApiFetch(api.getUserPurchases(userId!), 'purchases'),
    [userId],
    { waitUntil: [userId] }
  );
}

export function useTenantUserPaymentMethods(
  tenantId?: number,
  userId?: number
) {
  return use(
    () =>
      wrapApiFetch(
        api.getTenantUserPaymentMethods(tenantId!, userId!),
        'paymentMethods'
      ),
    [tenantId, userId],
    { waitUntil: [tenantId, userId] }
  );
}

export function useUserEnrolments(userId?: number) {
  return use(
    () => wrapApiFetch(api.getUserEnrolments(userId!), 'enrolments'),
    [userId],
    { waitUntil: [userId] }
  );
}

export function useUserPreenrolments(userId?: number) {
  return use(
    () => wrapApiFetch(api.getUserPreenrolment(userId!), 'preenrolments'),
    [userId],
    { waitUntil: [userId] }
  );
}

export function useUserIncompletePreenrolments(userId?: number) {
  return use(
    () =>
      wrapApiFetch(
        api.getUserIncompeletePreenrolment(userId!),
        'preenrolments'
      ),
    [userId],
    { waitUntil: [userId] }
  );
}

export function useUserStripeCharges(userId?: number, tenantId?: number) {
  return use(
    () =>
      wrapApiFetch(
        api.getUserStripeCharges(userId!, tenantId!),
        'stripeCharges'
      ),
    [userId, tenantId],
    { waitUntil: [userId, tenantId] }
  );
}
