import api from '../api';
import storage from '../storage/storage.isomorphic';
import { FetchError } from './fetcher';

function resolveOrDie<T>(r: T | FetchError) {
  if (r instanceof FetchError) {
    throw r;
  }
  return r;
}

export default async function initializeApplication({
  token,
  refreshToken
}: {
  token: string;
  refreshToken: string;
}): Promise<void> {
  storage.setItem('token', token);
  storage.setItem('refreshToken', refreshToken);
  const { me } = resolveOrDie(await api.getMe());
  storage.setItem('userId', me.id.toString());
  const { tenants } = resolveOrDie(await api.getUserTenant(me.id));
  if (tenants) {
    const [primaryTenant] = tenants;
    if (primaryTenant) {
      storage.setItem('tenantId', primaryTenant.id.toString());
    }
  }
}
