import { useMemo, useState } from 'react';
import {
  Alert,
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import api from '../../api';
import { useNavigator } from '../../compass/navigator';
import { FetchError } from '../../helpers/fetcher';
import { formatDate } from '../../helpers/formatDate';
import { useMe, useProgramUserPreenrolments } from '../../hooks';
import Money from '../../lib/money';
import { Child, Program } from '../../types';
import Button from '../buttons/button';
import Card from '../card';
import ChildCartItem from '../child/child-cart-item';
import ChildSelectItem from '../child/child-select-item';
import DescriptionItem from '../description-item';
import Loading from '../loading';
import {
  Heading0,
  Heading1,
  Heading3,
  Heading5,
  Paragraph1
} from '../text-family';
import ProgramImage from './program-image';

interface Props {
  program: Program;
  children: Child[];
  numberRegistered?: number;
}

export default function ProgramDescription({
  program,
  children,
  numberRegistered
}: Props) {
  const navigator = useNavigator();
  const [selected, setSelected] = useState<number[]>([]);
  const isMobile = Dimensions.get('screen').width < 768;
  const { data: me } = useMe();
  const { data: preenrolments } = useProgramUserPreenrolments(
    program.id,
    me?.id
  );
  const availableSlots = useMemo(() => {
    if (
      !program.capacity.programCapacityRequired ||
      !program.capacity.programCapacity
    )
      return Infinity;
    if (!numberRegistered) return program.capacity.programCapacity;
    return program.capacity.programCapacity - numberRegistered;
  }, [program, numberRegistered]);
  const eligibleChildren = useMemo(() => {
    if (!preenrolments) {
      return [];
    }
    return children.filter((child) => {
      for (const preenrolment of preenrolments) {
        for (const participant of preenrolment.participants) {
          if (
            participant.type === 'child' &&
            participant.child?.id === child.id
          ) {
            return false;
          }
        }
      }
      return true;
    });
  }, [children, preenrolments]);

  const alreadyRegisteredChildren = useMemo(() => {
    if (!preenrolments) {
      return [];
    }
    const alreadyRegisteredChildrenInner: Child[] = [];
    for (const preenrolment of preenrolments) {
      for (const participant of preenrolment.participants) {
        if (participant.type === 'child' && preenrolment.enrolled) {
          alreadyRegisteredChildrenInner.push(participant.child!);
        }
      }
    }
    return alreadyRegisteredChildrenInner;
  }, [preenrolments]);

  const inProgressPreenrolments = useMemo(() => {
    if (!preenrolments) {
      return [];
    }
    return preenrolments.filter((preenrolment) => !preenrolment.enrolled);
  }, [preenrolments]);

  const [preenrolmentContinueLoading, setPreenrolmentContinueLoading] =
    useState<Record<number, boolean>>({});

  async function onPressContinue(preenrolmentId: number) {
    setPreenrolmentContinueLoading({
      ...preenrolmentContinueLoading,
      [preenrolmentId]: true
    });
    try {
      const preenrolmentResult = await api.getPreenrolment(preenrolmentId);
      if (preenrolmentResult instanceof FetchError) {
        return Alert.prompt(
          'Error continuing registration. Please try again later.'
        );
      }
      const { preenrolment } = preenrolmentResult;
      const children = preenrolments
        ?.find((p) => p.preenrolment_id === preenrolmentId)
        ?.participants.map((participant) => participant.child!)!;
      navigator.Go('Registration', {
        program,
        children,
        preenrolment
      });
    } finally {
      setPreenrolmentContinueLoading({
        ...preenrolmentContinueLoading,
        [preenrolmentId]: false
      });
    }
  }

  function SelectChildView() {
    return (
      <View>
        {!alreadyRegisteredChildren && !preenrolments ? (
          <Loading />
        ) : alreadyRegisteredChildren.length ? (
          <>
            <Heading3>Already registered</Heading3>
            <View>
              <Paragraph1>
                {alreadyRegisteredChildren.map((child, index) => (
                  <View
                    key={index}
                    style={{
                      paddingRight:
                        index < alreadyRegisteredChildren.length - 1 ? '2%' : 0
                    }}
                  >
                    <ChildCartItem child={child} />
                  </View>
                ))}
              </Paragraph1>
            </View>
          </>
        ) : null}
        {inProgressPreenrolments.length ? (
          <>
            <Heading3>In progress</Heading3>
            {inProgressPreenrolments.map((preenrolment) => (
              <View
                key={preenrolment.preenrolment_id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <View
                  style={{
                    maxWidth: '50%'
                  }}
                >
                  <Paragraph1>
                    {preenrolment.participants
                      .filter((participant) => participant.type === 'child')
                      .map(
                        (participant) =>
                          `${participant.child?.firstName} ${participant.child?.lastName}`
                      )
                      .join(', ')}
                  </Paragraph1>
                </View>
                <View>
                  <Button
                    type="outline"
                    onPress={() =>
                      onPressContinue(preenrolment.preenrolment_id)
                    }
                  >
                    {preenrolmentContinueLoading[preenrolment.preenrolment_id]
                      ? 'Loading...'
                      : 'Continue'}
                  </Button>
                </View>
              </View>
            ))}
          </>
        ) : null}
        <Heading3 style={{ paddingVertical: 8 }}>Select Child</Heading3>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '99%',
            flexWrap: 'wrap',
            paddingBottom: 12
          }}
        >
          {!preenrolments ? (
            <Loading />
          ) : eligibleChildren.length ? (
            eligibleChildren.map((child, i) => (
              <TouchableOpacity
                key={i}
                style={{
                  // alignContent: 'center',
                  flexWrap: 'wrap',
                  width: '33%',
                  paddingHorizontal: 4,
                  paddingVertical: 8
                }}
                onPress={() => {
                  if (selected.includes(child.id)) {
                    const tempArray = selected.slice();
                    tempArray.splice(selected.indexOf(child.id), 1);
                    setSelected(tempArray);
                  } else {
                    if (!(selected.length >= availableSlots)) {
                      const tempArray = selected.slice();
                      tempArray.push(child.id);
                      setSelected(tempArray);
                    }
                  }
                }}
              >
                <ChildSelectItem
                  child={child}
                  selected={selected.includes(child.id)}
                />
              </TouchableOpacity>
            ))
          ) : (
            <Text style={{ paddingVertical: 8 }}>
              No available children to register
            </Text>
          )}
        </View>
        <Button
          type={selected.length > 0 ? 'primary' : 'disabled'}
          style={{ width: '50%' }}
          onPress={() => {
            if (selected.length > 0) {
              navigator.Go('ProgramDetails', {
                programId: program.id,
                childId: selected
              });
            }
          }}
        >
          Register
        </Button>
      </View>
    );
  }

  return (
    <ScrollView>
      <View style={{ padding: isMobile ? 8 : 80 }}>
        <Heading0
          style={{
            color: isMobile ? 'black' : 'white',
            marginBottom: isMobile ? 0 : 100,
            fontSize: isMobile ? 30 : 45,
            paddingHorizontal: 16
          }}
        >
          Registration
        </Heading0>
        <Card style={{ width: '95%', alignSelf: 'center' }}>
          <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <View style={{ width: isMobile ? '100%' : '30%' }}>
              <ProgramImage
                programId={program.id}
                style={{
                  height: 230,
                  width: 'auto',
                  marginVertical: isMobile ? 0 : -100,
                  borderRadius: 20,
                  marginBottom: 16
                }}
              />

              {!isMobile && <SelectChildView />}
            </View>
            <View
              style={{
                padding: isMobile ? 0 : 20,
                marginTop: isMobile ? 0 : -100,
                width: isMobile ? '99%' : '70%'
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Heading1
                  style={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    color: isMobile ? 'black' : 'white'
                  }}
                >
                  {program.pricing.serializedMoney
                    ? Money.deserialize(
                        program.pricing.serializedMoney
                      ).format()
                    : 'Free'}
                </Heading1>
                {program.pricing.serializedMoney && (
                  <Heading5 style={{ color: isMobile ? 'black' : 'white' }}>
                    Registration Fee
                  </Heading5>
                )}
              </View>
              <DescriptionItem title="Program">{program.name}</DescriptionItem>
              <DescriptionItem title="Program Start Date">
                {formatDate(program.programStartDate)}
              </DescriptionItem>
              <DescriptionItem title="Program End Date">
                {formatDate(program.programEndDate)}
              </DescriptionItem>
              <DescriptionItem title="Registration Start Date">
                {formatDate(program.registrationStartDate)}
              </DescriptionItem>
              <DescriptionItem title="Registration End Date">
                {formatDate(program.registrationEndDate)}
              </DescriptionItem>
              {
                <DescriptionItem title="Capacity">
                  {program.capacity.programCapacityRequired
                    ? numberRegistered +
                      ' / ' +
                      program.capacity.programCapacity
                    : 'Unlimited'}
                </DescriptionItem>
              }
              <DescriptionItem title="Description">
                {program.programDescription}
              </DescriptionItem>
            </View>

            {isMobile && <SelectChildView />}
          </View>
        </Card>
      </View>
    </ScrollView>
  );
}
