import { useState, useEffect } from 'react';
import storage from '../storage/storage.isomorphic';

export default function useMeId() {
  const [userId, setUserId] = useState<number>();

  async function getMeId() {
    const result = await storage.getItem('userId');
    if (!result) {
      throw new Error('Tenant Id is not set');
    }
    setUserId(+result);
  }

  useEffect(() => {
    getMeId();
  }, []);

  return userId;
}
