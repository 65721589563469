import React from 'react';
import { View, ScrollView, FlatList } from 'react-native';
import { User } from 'react-native-feather';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import {
  Heading3,
  Heading5,
  Heading6,
  Paragraph1,
  Paragraph2
} from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { breakpoint } from '../../../helpers/breakpoint';
import { useAuth } from '../../../context/auth-context';
import useMe from '../../../hooks/use-me';
import Loading from '../../../components/loading';
import { useNavigator } from '../../../compass/navigator';
import PageTitle from '../../../components/page-title';
import ContentContainer from '../../../components/content-container';
import Divider from '../../../components/divider';
import Label from '../../../components/label';
import { useUserTenantEntries } from '../../../hooks';
import useTenantId from '../../../hooks/use-tenant-id';
import getFieldData from '../../../helpers/getFieldData';

export default function Profile() {
  const auth = useAuth();
  const isMobile = breakpoint() == Screen.sm;
  const me = useMe();
  const screenSize = breakpoint();
  const tenantId = useTenantId();
  const navigator = useNavigator();
  const { data: fields } = useUserTenantEntries(me?.id, tenantId);

  return (
    <View style={{ flex: 1 }}>
      {me ? (
        <ScrollView>
          <PageTitle title="Profile" />
          <ContentContainer>
            <Card>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <View
                  style={{
                    width: '70%',
                    maxWidth: '70%',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <View
                    style={{
                      backgroundColor: 'rgba(6,255,255,0.5)',
                      width: screenSize == Screen.lg ? 64 : 40,
                      height: screenSize == Screen.lg ? 64 : 40,
                      borderRadius: 50,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <User
                      width={screenSize == Screen.lg ? 40 : 24}
                      height={screenSize == Screen.lg ? 40 : 24}
                      color={Colors.Black}
                      strokeWidth={1}
                    />
                  </View>
                  <View
                    style={{
                      marginLeft: screenSize == Screen.lg ? 20 : 10,
                      width: '80%'
                    }}
                  >
                    <Heading3 style={{ marginVertical: 0 }}>
                      {me.firstName}, {me.lastName}
                    </Heading3>
                    <Heading6 style={{ marginVertical: 0 }}>
                      {me.username}
                    </Heading6>
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Paragraph2 style={{ marginVertical: 0 }}>
                        Pickup Passcode:{' '}
                      </Paragraph2>
                      {me.pickupPasscode ? (
                        <Heading6 style={{ flexWrap: 'wrap' }}>
                          {me.pickupPasscode}
                        </Heading6>
                      ) : (
                        <Label type="danger">No Data</Label>
                      )}
                    </View>
                  </View>
                </View>

                <View>
                  <Button
                    type="outline"
                    onPress={() => navigator.Go('ProfileDetails')}
                  >
                    Edit Details
                  </Button>
                </View>
              </View>
            </Card>

            {isMobile ? (
              <View
                style={{
                  width: '98%',
                  alignSelf: 'center',
                  marginVertical: 20
                }}
              >
                <Button
                  type="danger"
                  onPress={async () => {
                    auth.clear();
                  }}
                >
                  Logout
                </Button>
              </View>
            ) : null}

            <Divider orientation="horizontal" />
            <FlatList
              data={fields}
              renderItem={({ item, index }) => (
                <View key={index}>
                  <View
                    style={{
                      flexDirection: screenSize == Screen.lg ? 'row' : 'column',
                      justifyContent: 'space-between',
                      alignContent: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        width: screenSize == Screen.lg ? '40%' : 'none'
                      }}
                    >
                      <Heading5 style={{ fontSize: 11, color: Colors.Gray }}>
                        {item.field.name}
                      </Heading5>
                    </View>
                    <View
                      style={{
                        width: screenSize == Screen.lg ? '50%' : 'none',
                        alignItems:
                          screenSize == Screen.lg ? 'flex-end' : 'flex-start'
                      }}
                    >
                      <Paragraph1 style={{ color: Colors.Black }}>
                        {getFieldData(item) ? (
                          getFieldData(item)
                        ) : (
                          <Label type="warning">No Data</Label>
                        )}
                      </Paragraph1>
                    </View>
                  </View>
                  <Divider orientation="horizontal" />
                </View>
              )}
            />
          </ContentContainer>
        </ScrollView>
      ) : (
        <Loading />
      )}
    </View>
  );
}
