import moment from 'moment';
import { ReactNode } from 'react';
import { DataFieldType } from '../constants';
import { Fillable } from '../types';

export default function getFieldData(field: Fillable): ReactNode {
  const date = (field.entry?.data as { date: number } | undefined)?.date;
  switch (field.field.type) {
    case DataFieldType.Number:
      return (field.entry?.data as { number: number } | undefined)?.number;
    case DataFieldType.Text:
    case DataFieldType.Email:
    case DataFieldType.PhoneNumber:
    case DataFieldType.PostalCode:
      return (field.entry?.data as { text: string } | undefined)?.text;
    case DataFieldType.TextArea:
      return (field.entry?.data as { text: string } | undefined)?.text;
    case DataFieldType.Date:
      return date !== undefined
        ? moment.unix(date).format('MMM DD, YYYY')
        : undefined;
    case DataFieldType.Select:
      return (field.entry?.data as { selection: string } | undefined)
        ?.selection;
    case DataFieldType.Waiver:
      return field.entry?.data ? 'Waiver agreed' : 'Waiver not agreed';
    default:
      return null;
  }
}
