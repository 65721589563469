import React from 'react';
import { Dimensions, View, ViewProps } from 'react-native';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';
import { SafeAreaView } from 'react-native-safe-area-context';

interface Props extends ViewProps {}

export default function PageContainerAuth(props: Props) {
  return (
    <SafeAreaView
      edges={['top']}
      style={{
        flex: 1
      }}
    >
      <View
        {...composePropsWithDefaultStyles(
          {
            height: Dimensions.get('window').height,
            padding: 5
          },
          props as any
        )}
      />
    </SafeAreaView>
  );
}
