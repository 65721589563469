import { TextArea, Box } from 'native-base';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Colors } from '../../constants';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import { Paragraph2 } from '../text-family';

export default function TextAreaField({
  field,
  entry,
  onChange,
  displayFormError
}: FieldProps) {
  const [text, setText] = useState<string | undefined>(
    entry ? (entry?.data as { text: string }).text : ''
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!text?.length : true,
    message: 'This field is required'
  });
  return (
    <View>
      <Box alignItems="center" w="100%">
        <TextArea
          fontFamily={'Poppins-Regular'}
          fontSize={14}
          value={text}
          borderRadius={15}
          margin={2}
          borderColor={
            displayFormError || !error.valid
              ? 'rgba(255, 0, 0, 1)'
              : 'rgba(112, 112, 112, 0.5)'
          }
          backgroundColor={Colors.White}
          borderWidth={1}
          autoCompleteType={''}
          onChangeText={(text) => {
            setText(text);
            if (onChange) {
              onChange({ text }, fieldIsValid(field, { text }).valid);
            }
            setError(fieldIsValid(field, { text }));
          }}
          w="100%"
        />
      </Box>
      {field.mandatory ? (
        !error.valid ? (
          <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
            {error?.message}
          </Paragraph2>
        ) : null
      ) : null}
    </View>
  );
}
