import React from 'react';
import { View, ViewProps } from 'react-native';
import { Smile } from 'react-native-feather';
import { Colors } from '../../constants';
import { Child, User } from '../../types';
import { Paragraph1 } from '../text-family';

interface Props extends ViewProps {
  child?: Child | User;
}

export default function ChildCartItem(props: Props) {
  return (
    <View style={{ paddingVertical: 4 }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <View style={{}}>
          <View
            style={{
              backgroundColor: 'rgba(6,255,255,0.5)',
              width: 20,
              height: 20,
              borderRadius: 50,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Smile
              width={15}
              height={15}
              color={Colors.Black}
              strokeWidth={1}
            />
          </View>
        </View>

        <Paragraph1 style={{ marginLeft: 4, fontSize: 12 }}>
          {props.child?.firstName}
        </Paragraph1>
      </View>
    </View>
  );
}
