import moment from 'moment';
import React, { useState } from 'react';
import { View } from 'react-native';

interface Props {
  error?: boolean;
  value?: number;
  onChange?: (value: number) => void;
}

export default function DatePicker({ value, onChange, error }: Props) {
  const [date, setDate] = useState<number | undefined>(value);

  return (
    <View style={{}}>
      <input
        id="date-picker"
        style={{
          backgroundColor: 'white',
          borderColor: error === true ? 'red' : 'rgba(112, 112, 112, 0.5)',
          borderWidth: 1,
          borderRadius: 15,
          margin: 4,
          paddingTop: 6,
          paddingBottom: 6,
          paddingRight: 4,
          paddingLeft: 4,
          fontFamily: 'Poppins-Regular'
        }}
        defaultValue={date ? moment.unix(date).format('YYYY-MM-DD') : undefined}
        onChange={(e) => {
          setDate(moment(e.target.value).unix());
          if (onChange) {
            onChange(moment(e.target.value).unix());
          }
        }}
        type="date"
      />
    </View>
  );
}
