import { View } from 'react-native';
import { Program } from '../../types';
import Button from '../buttons/button';
import Card from '../card';
import moment from 'moment';
import { Heading4, Paragraph2 } from '../text-family';
import { useNavigator } from '../../compass/navigator';
import Money from '../../lib/money';
import ProgramImage from './program-image';

interface Props {
  program: Program;
}

export default function ProgramCard({ program }: Props) {
  const { Go } = useNavigator();

  return (
    <Card
      style={{
        height: 350,
        padding: 0,
        margin: 20,
        width: 300,
        alignSelf: 'center'
      }}
    >
      <ProgramImage
        programId={program.id}
        style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        type="background"
        containerStyle={{
          padding: 20,
          height: 160,
          width: 'auto'
        }}
      >
        <View style={{ alignItems: 'flex-end' }}>
          <View
            style={{
              backgroundColor: 'white',
              width: 64,
              display: 'flex',
              height: 64,
              borderColor: 'rgba(0, 0, 0, 0.4)',
              borderWidth: 1,
              borderRadius: 50,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Heading4 style={{ fontSize: 14, color: 'black' }}>
              {program.pricing.serializedMoney
                ? Money.deserialize(program.pricing.serializedMoney).format()
                : 'Free'}
            </Heading4>
          </View>
        </View>
      </ProgramImage>
      <View style={{ flex: 1, padding: 10 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: 1
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Heading4 style={{ paddingTop: 16 }}>{program.name}</Heading4>
            <Paragraph2 style={{ marginBottom: 16 }}>
              {moment(program.registrationStartDate).format('LL')} -{' '}
              {moment(program.registrationEndDate).format('LL')}
            </Paragraph2>
          </View>
          <Button
            style={{ width: '50%' }}
            onPress={() => Go('Program', { programId: program.id })}
          >
            Read More
          </Button>
        </View>
      </View>
    </Card>
  );
}
