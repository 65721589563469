import {
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  View,
  Text
} from 'react-native';
import { useNavigator } from '../../../compass/navigator';
import Card from '../../../components/card';
import ContentContainer from '../../../components/content-container';
import PageLoading from '../../../components/full-page-loading';
import PageTitle from '../../../components/page-title';
import { breakpoint } from '../../../helpers/breakpoint';
import { useUserStripeCharges } from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';
import useTenantId from '../../../hooks/use-tenant-id';
import { Colors, Screen } from '../../../constants';
import Divider from '../../../components/divider';
import {
  Heading5,
  Paragraph1,
  Paragraph2
} from '../../../components/text-family';
import * as Linking from 'expo-linking';
import moment from 'moment';
import Badge from '../../../components/badge';
import Label from '../../../components/label';
// import Input from '../../../components/input';

export default function Receipt() {
  const meId = useMeId();
  const tenantId = useTenantId();
  // const navigator = useNavigator();
  const screenSize = breakpoint();

  const { data: userCharges } = useUserStripeCharges(meId, tenantId);

  return !userCharges ? (
    <PageLoading />
  ) : (
    <View style={{ flex: 1 }}>
      <PageTitle title="Receipts" />
      {/* <View
        style={{
          paddingTop: screenSize !== Screen.sm ? 16 : 8,
          paddingHorizontal: screenSize !== Screen.sm ? '12%' : 18
        }}
      >
        <Input placeholder="Search" />
      </View> */}

      <ScrollView style={{ flex: 1 }}>
        <ContentContainer>
          <Card style={{ padding: screenSize == Screen.lg ? 20 : 12 }}>
            <View style={{ width: '100%' }}>
              <FlatList
                data={userCharges.data}
                renderItem={({ item, index }) => (
                  <View key={index}>
                    <View>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <View
                          style={{
                            width: '85%',
                            alignSelf: 'flex-start'
                          }}
                        >
                          <Text
                            style={{
                              alignItems: 'center',
                              flexDirection: 'row'
                            }}
                          >
                            <Heading5>
                              ${item.amount / 100} {item.currency.toUpperCase()}
                            </Heading5>
                            <Paragraph2>on</Paragraph2>

                            <Paragraph2>
                              {moment.unix(item.created).format('LLL')}
                            </Paragraph2>
                          </Text>
                        </View>
                        <View style={{ width: '15%', alignItems: 'flex-end' }}>
                          {item.receipt_url && item.receipt_url.length ? (
                            <Pressable
                              onPress={() =>
                                Platform.OS == 'web'
                                  ? window.open(item.receipt_url, '_blank')
                                  : Linking.openURL(item.receipt_url || '')
                              }
                            >
                              <Paragraph2
                                style={{
                                  textDecorationLine: 'underline',
                                  color: Colors.Primary
                                }}
                              >
                                View
                              </Paragraph2>
                            </Pressable>
                          ) : null}
                        </View>
                      </View>
                      <Label type="success">Paid</Label>
                    </View>
                    {index < userCharges.data.length - 1 && (
                      <Divider
                        orientation="horizontal"
                        style={{ marginVertical: 10 }}
                      />
                    )}
                  </View>
                )}
              />
            </View>
          </Card>
        </ContentContainer>
      </ScrollView>
    </View>
  );
}
