import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  FlatList,
  View,
  Image,
  Platform,
  Dimensions,
  ScrollView
} from 'react-native';
import api from '../../../api';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import ContentContainer from '../../../components/content-container';
import Divider from '../../../components/divider';
import PageLoading from '../../../components/full-page-loading';
import {
  Heading2,
  Heading3,
  Heading4,
  Heading6,
  Paragraph1
} from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { breakpoint } from '../../../helpers/breakpoint';
import { useNotify } from '../../../helpers/use-notify';
import {
  usePrograms,
  useTenantUserPaymentMethods,
  useUserPurchases
} from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';
import useTenantId from '../../../hooks/use-tenant-id';
import Money from '../../../lib/money';
import { Program } from '../../../types';
import { Modal } from '../../../components/modal';
import PageTitle from '../../../components/page-title';
import { Check } from 'react-feather';
import HoverableOpacity from '../../../components/buttons/hoverable-opacity';
import ProgramImage from '../../../components/program/program-image';
import { useNavigator } from '../../../compass/navigator';

export default function Billing() {
  const screenSize = breakpoint();
  const meId = useMeId();
  const tenantId = useTenantId();
  const navigator = useNavigator();

  const { data: purchases, loading: loadingPurchases } = useUserPurchases(meId);
  const { data: paymentMethods, refresh: refreshPaymentMethods } =
    useTenantUserPaymentMethods(tenantId, meId);
  const { data: programs } = usePrograms(tenantId!);
  const [addPaymentMethodLoading, setAddPaymentMethodLoading] =
    React.useState(false);
  const { error, success, alert } = useNotify();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();
  const [
    defaultPaymentMethodContinueLoading,
    setDefaultPaymentMethodContinueLoading
  ] = useState(false);

  const programLookup = useMemo(() => {
    if (!programs) {
      return {};
    }
    const lookup: Record<number, Program> = {};
    for (const program of programs) {
      lookup[program.id] = program;
    }
    return lookup;
  }, [programs]);

  const paymentHistoryFormated = useMemo(
    () =>
      purchases
        ?.map((purchase) => ({
          amount: Money.deserialize(purchase.total).format(),
          program: programLookup[purchase.programId]?.name,
          programId: purchase.programId,
          date: moment.unix(purchase.createdAt).format('MMM DD, YYYY h:mm a')
        }))
        .sort((a, b) => b.date.localeCompare(a.date)),
    [purchases, programLookup]
  );

  async function onPressAddPaymentMethod() {
    if (!tenantId || !meId) {
      return;
    }
    try {
      setAddPaymentMethodLoading(true);
      const returnUrl =
        Platform.OS === 'web' ? window.location.href : 'enrolr://billing';
      const result = await api.postTenantUserPaymentMethod(
        tenantId,
        meId,
        returnUrl,
        returnUrl
      );
      if (result instanceof Error) {
        throw result;
      }
      if (Platform.OS === 'web') {
        window.location.href = result.url;
      }
    } catch {
      error(
        "Error adding payment method. Please ensure you're connected to the internet and try again."
      );
    } finally {
      setAddPaymentMethodLoading(false);
    }
  }

  function onPressChangeDefaultPaymentMethod() {
    setModalVisible(true);
  }

  async function onChangeDefaultPaymentMethodContinue() {
    if (!tenantId || !meId) {
      return;
    }
    if (!selectedPaymentMethod) {
      return alert('Please select a payment method');
    }
    setDefaultPaymentMethodContinueLoading(true);
    try {
      const result = await api.putTenantUserDefaultPaymentMethod(
        tenantId,
        meId,
        selectedPaymentMethod
      );
      if (result instanceof Error) {
        throw result;
      }
      await refreshPaymentMethods();
      setModalVisible(false);
      success('Default payment method changed successfully');
    } catch {
      error(
        "Error changing default payment method. Please ensure you're connected to the internet and try again."
      );
    } finally {
      setDefaultPaymentMethodContinueLoading(false);
    }
  }

  return !purchases || loadingPurchases || !programs || !paymentMethods ? (
    <PageLoading />
  ) : (
    <View style={{ flex: 1 }}>
      <Modal
        visible={modalVisible}
        size={screenSize == Screen.sm ? '90%' : '50%'}
        setVisible={setModalVisible}
      >
        <FlatList
          style={{ marginTop: 8 }}
          data={paymentMethods.filter((pm) => !pm.default)}
          renderItem={({ item, index }) => (
            <HoverableOpacity
              onPress={() => setSelectedPaymentMethod(item.id)}
              key={index}
            >
              <Card
                style={{
                  backgroundColor:
                    selectedPaymentMethod === item.id
                      ? Colors.Background
                      : Colors.White,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '95%',
                  justifyContent: 'space-between'
                }}
              >
                <View
                  style={{
                    maxWidth: 100,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <View>
                    <Image
                      source={{
                        uri: item.data.brand
                          ? item.data.brand === 'visa'
                            ? 'https://assets.rivercitytech.cloud/nodes/1/enrolr/visa.img'
                            : item.data.brand === 'mastercard'
                            ? 'https://assets.rivercitytech.cloud/nodes/1/enrolr/mastercard.img'
                            : 'https://assets.rivercitytech.cloud/nodes/1/enrolr/generic_cc.img'
                          : 'https://assets.rivercitytech.cloud/nodes/1/enrolr/bank.img'
                      }}
                      style={{
                        resizeMode: 'contain',
                        height: 40,
                        width: 60
                      }}
                    />
                  </View>
                  <View
                    style={{
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      flex: 1,
                      marginLeft: 16
                    }}
                  >
                    <View>
                      <Paragraph1>
                        {(item.data.brand
                          ? item.data.brand
                          : item.data.bankName
                        )?.toUpperCase()}
                      </Paragraph1>
                    </View>
                    <View style={{ marginLeft: 4 }}>
                      <Paragraph1>
                        {item.data.brand
                          ? `**** **** **** ${item.data.last4}`
                          : `Account ending in ${item.data.last4}`}
                      </Paragraph1>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display:
                      selectedPaymentMethod === item.id ? undefined : 'none'
                  }}
                >
                  <Check color="green" size={32} />
                </View>
              </Card>
            </HoverableOpacity>
          )}
        />
        <Button
          type="primary"
          loading={defaultPaymentMethodContinueLoading}
          onPress={onChangeDefaultPaymentMethodContinue}
        >
          {defaultPaymentMethodContinueLoading ? 'Loading...' : 'Continue'}
        </Button>
      </Modal>
      <PageTitle
        title="Billing"
        button={
          <Button
            type="white"
            onPress={() => {
              navigator.Go('Receipt');
            }}
          >
            View Receipts
          </Button>
        }
      />
      <ScrollView style={{ flex: 1 }}>
        <ContentContainer>
          <Card style={{ padding: screenSize == Screen.lg ? 20 : 8 }}>
            <View
              style={{
                flexDirection: screenSize == Screen.lg ? 'row' : 'column',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <View
                style={{
                  width: screenSize == Screen.lg ? '48%' : '100%'
                }}
              >
                <Heading3>Purchases</Heading3>
                <FlatList
                  data={paymentHistoryFormated}
                  renderItem={({ item, index }) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        alignContent: 'flex-start',
                        padding: 16
                      }}
                    >
                      <View>
                        <ProgramImage
                          programId={item.programId}
                          style={{
                            height: screenSize == Screen.sm ? 50 : 75,
                            width: screenSize == Screen.sm ? 80 : 120,
                            borderRadius: 20
                          }}
                        />
                      </View>
                      <View
                        style={{
                          flexDirection: 'column',
                          width:
                            screenSize == Screen.lg
                              ? ((Dimensions.get('window').width / 1.3 - 160) /
                                  Dimensions.get('window').width) *
                                  100 +
                                '%'
                              : ((Dimensions.get('window').width / 1.3 - 100) /
                                  Dimensions.get('window').width) *
                                  100 +
                                '%'
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            marginLeft: 10
                          }}
                        >
                          <Heading4>{item.program}</Heading4>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginLeft: 10
                            }}
                          >
                            <View style={{}}>
                              <Heading6 style={{ color: Colors.Black }}>
                                {item.date}
                              </Heading6>
                            </View>
                            <View>
                              <Heading2>{item.amount}</Heading2>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                />
              </View>

              <Divider
                orientation={
                  screenSize == Screen.lg ? 'vertical' : 'horizontal'
                }
              />
              <View style={{ width: screenSize == Screen.lg ? '48%' : '100%' }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    padding: 16
                  }}
                >
                  <View>
                    <Heading3>Payment Methods</Heading3>
                  </View>
                  <View style={{ marginTop: 8 }}>
                    <Button type="outline" onPress={onPressAddPaymentMethod}>
                      {addPaymentMethodLoading
                        ? 'Loading...'
                        : 'Add Payment Method'}
                    </Button>
                  </View>
                  <View style={{ marginTop: 8 }}>
                    <Button
                      type="outline"
                      onPress={onPressChangeDefaultPaymentMethod}
                    >
                      Change Payment Method for Invoices
                    </Button>
                  </View>
                </View>
                <FlatList
                  style={{ marginTop: 8 }}
                  data={paymentMethods}
                  renderItem={({ item, index }) => (
                    <Card
                      key={index}
                      style={{ backgroundColor: Colors.OffWhite }}
                    >
                      <View
                        style={{ flexDirection: 'row', alignItems: 'center' }}
                      >
                        <Image
                          source={{
                            uri: item.data.brand
                              ? item.data.brand === 'visa'
                                ? 'https://assets.rivercitytech.cloud/nodes/1/enrolr/visa.img'
                                : item.data.brand === 'mastercard'
                                ? 'https://assets.rivercitytech.cloud/nodes/1/enrolr/mastercard.img'
                                : 'https://assets.rivercitytech.cloud/nodes/1/enrolr/generic_cc.img'
                              : 'https://assets.rivercitytech.cloud/nodes/1/enrolr/bank.img'
                          }}
                          style={{
                            resizeMode: 'contain',
                            height: 40,
                            width: 60
                          }}
                        />
                        {item.default ? (
                          <View style={{ marginLeft: 12 }}>
                            <Heading4 style={{ color: 'green' }}>
                              (Used for invoicing)
                            </Heading4>
                          </View>
                        ) : null}
                      </View>
                      <View
                        style={{
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                          flex: 1,
                          marginLeft: 16
                        }}
                      >
                        <View>
                          <Paragraph1>
                            {(item.data.brand
                              ? item.data.brand
                              : item.data.bankName
                            )?.toUpperCase()}
                          </Paragraph1>
                        </View>
                        <View style={{ marginLeft: 4 }}>
                          <Paragraph1>
                            {item.data.brand
                              ? `**** **** **** ${item.data.last4}`
                              : `Account ending in ${item.data.last4}`}
                          </Paragraph1>
                        </View>
                      </View>
                    </Card>
                  )}
                />
              </View>
            </View>
          </Card>
        </ContentContainer>
      </ScrollView>
    </View>
  );
}
