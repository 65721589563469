import React from 'react';
import { Dimensions, View, ViewProps } from 'react-native';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';
import SideNavigationBar from '../navigation-bar/side-navigation-bar';
import { Colors } from '../../constants';
import useMe from '../../hooks/use-me';

interface Props extends ViewProps {}

export default function PageContainerDesk(props: Props) {
  const me = useMe();

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: Colors.Background,
        height: Dimensions.get('window').height,
        width: Dimensions.get('window').width
      }}
    >
      <SideNavigationBar user={me} />
      <View style={{ flex: 1 }}>
        <View
          {...composePropsWithDefaultStyles(
            {
              flex: 1
            },
            props as any
          )}
        />
      </View>
    </View>
  );
}
