import { useEffect, useState } from 'react';
import { FetchError } from './fetcher';

export interface UseResult<T> {
  data: T | undefined;
  loading: boolean;
  error: false | Error;
  refresh: () => Promise<void>;
  mutate: (data: T) => void;
}

export default function use<T>(
  cb: () => Promise<T | FetchError<unknown>>,
  deps: any[] = [],
  options?: {
    waitUntil: any[];
  }
): UseResult<T> {
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<false | Error>(false);

  function mutate(data: T) {
    setData(data);
  }

  async function fetch() {
    if (options?.waitUntil && !options.waitUntil.every(Boolean)) {
      return;
    }
    setLoading(true);
    try {
      setData((await cb()) as T);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, deps);

  return {
    data,
    loading,
    error,
    refresh: fetch,
    mutate
  };
}
