import React, { useState } from 'react';
import { FlatList, ScrollView, View } from 'react-native';
import { Screen } from '../../constants';
import { breakpoint } from '../../helpers/breakpoint';
import { useTenantProgramTypes, useTenantProgramSites } from '../../hooks';
import useTenantId from '../../hooks/use-tenant-id';
import { FullProgram } from '../../types';
import ContentContainer from '../content-container';
import PageTitle from '../page-title';
import ProgramCard from './program-card';
import Button from '../buttons/button';
import { Select } from '../select';
import PageLoading from '../full-page-loading';
import Empty from '../empty';

interface Props {
  programs?: FullProgram[];
}

export default function ProgramList({ programs }: Props) {
  const screenSize = breakpoint();
  const [filteredPrograms, setFilteredPrograms] = useState<
    FullProgram[] | undefined
  >(programs);
  const tenantId = useTenantId();
  const [selectedType, setSelectedType] = useState('');
  const [selectedSite, setSelectedSite] = useState('');

  const { data: programTypes } = useTenantProgramTypes(tenantId);
  const { data: programSites } = useTenantProgramSites(tenantId);

  function onSearch() {
    if (
      (!selectedSite && !selectedType) ||
      (selectedSite === '0' && selectedType === '0')
    ) {
      setFilteredPrograms(programs);
      return;
    }
    const filtered = programs?.filter(({ program, site, type }) => {
      const siteMatch = selectedSite
        ? site.id.toString() === selectedSite
        : true;
      const typeMatch = selectedType
        ? type.id.toString() === selectedType
        : true;

      return siteMatch && typeMatch;
    });
    setFilteredPrograms(filtered);
  }

  // useEffect(() => setFilteredPrograms(programs), [programs]);
  return (
    <ScrollView style={{ flex: 1 }}>
      <View
        style={{
          width: '100%',
          flex: 1,
          alignItems: 'center'
        }}
      >
        <PageTitle title="Programs" />
        <ContentContainer>
          {!tenantId ? (
            <PageLoading />
          ) : !filteredPrograms?.length ? (
            <Empty
              title="No Programs"
              description={`We don't have any programs at this time. Please check back later.`}
            />
          ) : (
            <FlatList
              style={{
                width: '100%',
                flex: 1
              }}
              contentContainerStyle={
                screenSize !== Screen.sm && {
                  flex: 1,
                  width: '100%',
                  alignItems: 'center'
                }
              }
              ListEmptyComponent={
                <Empty
                  title="No Programs Found"
                  description={`Oops! Seems like we don't have any result for that search.`}
                />
              }
              ListHeaderComponentStyle={{ width: '100%' }}
              ListHeaderComponent={() => (
                <View style={{ width: '100%', flex: 1, alignItems: 'center' }}>
                  <View
                    style={{
                      flexDirection: screenSize == Screen.sm ? 'column' : 'row',
                      width: '99%',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={{
                        width: screenSize === Screen.sm ? '100%' : '40%',
                        paddingHorizontal: 4
                      }}
                    >
                      <Select
                        items={
                          programSites
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((site) => ({
                              label: site.name,
                              value: site.id
                            })) ?? []
                        }
                        selectedValue={selectedSite}
                        placeholder={{ label: 'Select Program Site', value: 0 }}
                        onValueChange={(option: string) =>
                          setSelectedSite(option === '0' ? '' : option)
                        }
                      />
                    </View>
                    <View
                      style={{
                        width: screenSize === Screen.sm ? '100%' : '40%',
                        paddingHorizontal: 4
                      }}
                    >
                      <Select
                        items={
                          programTypes
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((type) => ({
                              label: type.name,
                              value: type.id
                            })) ?? []
                        }
                        selectedValue={selectedType}
                        placeholder={{ label: 'Select Program Type', value: 0 }}
                        onValueChange={(option: string) =>
                          setSelectedType(option === '0' ? '' : option)
                        }
                      />
                    </View>
                    <View
                      style={{
                        width: screenSize === Screen.sm ? '100%' : '20%',
                        paddingHorizontal: 4,
                        marginTop: screenSize === Screen.sm ? 8 : ''
                      }}
                    >
                      <Button
                        style={{
                          width: screenSize === Screen.sm ? '40%' : '100%'
                        }}
                        onPress={() => onSearch()}
                      >
                        Search
                      </Button>
                    </View>
                  </View>
                </View>
              )}
              data={filteredPrograms}
              numColumns={
                screenSize == Screen.sm ? 1 : screenSize == Screen.md ? 2 : 3
              }
              renderItem={({ item, index }) => (
                <ProgramCard program={item.program} key={index} />
              )}
              key={
                screenSize === Screen.sm ? 1 : screenSize === Screen.md ? 2 : 3
              }
            />
          )}
        </ContentContainer>
      </View>
    </ScrollView>
  );
}
