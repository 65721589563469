import { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  View,
  Image
} from 'react-native';
import { CheckCircle } from 'react-native-feather';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import HoverableOpacity from '../../../components/buttons/hoverable-opacity';
import Input from '../../../components/input';
import { Heading1, Heading4, Heading5 } from '../../../components/text-family';
import { Colors } from '../../../constants';
import { buttonHorizontalWidth } from '../../../helpers/breakpoint';
import { useNotify } from '../../../helpers/use-notify';
import { usePublicTenants } from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';

export default function ChooseTenant() {
  const meId = useMeId();
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android';
  const { data: tenants, loading: loadingTenants } = usePublicTenants();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { error } = useNotify();

  const width = buttonHorizontalWidth();

  const navigator = useNavigator();

  const filteredTenants = useMemo(() => {
    if (!searchText) return tenants;
    return [...(tenants ?? [])].filter((tenant) =>
      tenant.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [tenants, searchText]);

  async function onPressContinue() {
    if (!selectedId || !meId) return;
    setLoading(true);
    try {
      navigator.Go('TenantFields', { tenantId: selectedId });
    } catch {
      error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <SafeAreaView
      edges={['top', 'bottom']}
      style={{
        flex: 1,
        backgroundColor: Colors.Background
      }}
    >
      {!meId ? (
        <ActivityIndicator />
      ) : (
        <View style={{ padding: isMobile ? 5 : '5%', flex: 1 }}>
          <Heading1>Choose service provider</Heading1>
          <Heading4 style={{ marginTop: 0 }}>
            Please select your service provider to continue!
          </Heading4>

          <View style={{ flex: 1 }}>
            {loadingTenants ? (
              <ActivityIndicator />
            ) : (
              <View style={{ flex: 1 }}>
                {/* <Heading2 style={{ marginTop: 4, marginBottom: 0 }}>
                Providers
              </Heading2> */}
                <View
                  style={{
                    width: '100%',
                    paddingHorizontal: 4
                  }}
                >
                  <Input
                    placeholder="Enter Search Keyword"
                    onChangeText={(text) => setSearchText(text)}
                  />
                </View>
                <FlatList
                  style={{ flex: 1, paddingHorizontal: 8 }}
                  data={filteredTenants}
                  renderItem={({ item, index }) => (
                    <HoverableOpacity
                      onPress={() =>
                        selectedId == item.id
                          ? setSelectedId(null)
                          : setSelectedId(item.id)
                      }
                      key={index}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingVertical: 10,
                        paddingHorizontal: 12,
                        borderBottomWidth: 1,
                        borderColor: Colors.GrayBorder,
                        backgroundColor:
                          selectedId == item.id
                            ? 'rgba(21,206,230,0.1)'
                            : 'transparent'
                      }}
                    >
                      <View
                        style={{
                          width: '70%',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <Image
                          resizeMode="contain"
                          style={{
                            width: 70,
                            height: 70,
                            alignItems: 'center'
                          }}
                          source={{
                            uri: `https://assets.rivercitytech.cloud/nodes/1/enrolr/tenants/${
                              item.id ?? 1
                            }/branding/main.img`
                          }}
                        />
                        <Heading5 style={{ marginLeft: 20 }}>
                          {item.name}
                        </Heading5>
                      </View>

                      {selectedId == item.id ? (
                        <View style={{ paddingRight: 10 }}>
                          <CheckCircle color="green" />
                        </View>
                      ) : null}
                    </HoverableOpacity>
                  )}
                />
                <View style={{ width: '100%', alignItems: 'center' }}>
                  <Button
                    type={'primary'}
                    style={{ width: width }}
                    onPress={onPressContinue}
                  >
                    {loading ? 'Loading...' : 'Continue'}
                  </Button>
                </View>
              </View>
            )}
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}
