import React, { useState } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import api from '../../../api';
import { useNavigator } from '../../../compass/navigator';
import Button from '../../../components/buttons/button';
import Card from '../../../components/card';
import ContentContainer from '../../../components/content-container';
import Field from '../../../components/custom-fields/field';
import Divider from '../../../components/divider';
import Loading from '../../../components/loading';
import PageTitle from '../../../components/page-title';
import { Heading6, Paragraph2 } from '../../../components/text-family';
import { Colors, Screen } from '../../../constants';
import { breakpoint } from '../../../helpers/breakpoint';
import { fieldIsValid } from '../../../helpers/field-is-valid';
import { fieldTrimmer } from '../../../helpers/field-trimmer';
import { useNotify } from '../../../helpers/use-notify';
import { useChildrenTenantFields } from '../../../hooks';
import useMeId from '../../../hooks/use-me-id';
import useTenantId from '../../../hooks/use-tenant-id';
import { DataField } from '../../../types';

export default function AddChild() {
  const screenSize = breakpoint();
  const tenantId = useTenantId();
  const meId = useMeId();
  const navigator = useNavigator();
  const { data: fields } = useChildrenTenantFields(tenantId!);
  // console.log(fields);
  const [updatedFields, setUpdatedFields] = useState<Record<number, object>>(
    {}
  );
  const [childData, setChildData] = useState({ firstName: '', lastName: '' });
  const { success, error } = useNotify();
  const [saving, setSaving] = useState(false);

  function checkValidity(
    fields: { field: DataField; value: object }[]
  ): boolean {
    return (
      Object.keys(childData).every(
        (key) =>
          childData[key as keyof typeof childData] !== undefined &&
          (childData[key as keyof typeof childData] as string).length >= 1
      ) &&
      fields.every(({ field, value }) => {
        return fieldIsValid(field, value).valid;
      })
    );
  }

  async function onSave() {
    setSaving(true);
    if (
      !checkValidity(
        fields?.map((field) => ({
          field,
          value: updatedFields[field.id] ?? {}
        })) ?? []
      )
    ) {
      error('Please check required fields');
      return;
    }
    try {
      await api.createChildWithDetails(meId!, {
        firstName: childData.firstName,
        lastName: childData.lastName,
        details: Object.entries(updatedFields).map(([id, value]) => ({
          fieldId: +id,
          value: fieldTrimmer(value)
        }))
      });
      navigator.Back();
      success('Successfully added child');
    } catch (e) {
      error('Failed to add child');
    } finally {
      setSaving(false);
    }
  }

  return (
    <ScrollView style={{ flex: 1 }}>
      <PageTitle title="Add Child" />
      <ContentContainer>
        {fields && meId ? (
          <>
            <Card>
              <View
                style={{
                  flexDirection: screenSize == Screen.lg ? 'row' : 'column',
                  justifyContent:
                    screenSize == Screen.lg ? 'space-between' : `flex-start`
                }}
              >
                <View
                  style={{ width: screenSize == Screen.lg ? '49%' : '100%' }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Heading6
                      style={{ marginLeft: 25, margin: 0, marginBottom: 4 }}
                    >
                      First Name
                    </Heading6>
                    <Heading6
                      style={{ color: 'red', margin: 0, marginBottom: 4 }}
                    >
                      *
                    </Heading6>
                  </View>
                  <TextInput
                    style={{
                      borderColor:
                        !childData.firstName || childData.firstName?.length < 2
                          ? 'red'
                          : 'rgba(112, 112, 112, 0.5)',
                      borderWidth: 1,
                      borderRadius: 15,
                      backgroundColor: Colors.White,
                      margin: 4,
                      marginVertical: 6,
                      paddingHorizontal: 6,
                      paddingVertical: 6
                    }}
                    value={childData.firstName}
                    onChangeText={(text) => {
                      setChildData({ ...childData, firstName: text.trim() });
                    }}
                  />
                  {!childData.firstName?.length && (
                    <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
                      Please enter a first name
                    </Paragraph2>
                  )}
                </View>

                <View
                  style={{ width: screenSize == Screen.lg ? '49%' : '100%' }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Heading6
                      style={{ marginLeft: 25, margin: 0, marginBottom: 4 }}
                    >
                      Last Name
                    </Heading6>
                    <Heading6
                      style={{ color: 'red', margin: 0, marginBottom: 4 }}
                    >
                      *
                    </Heading6>
                  </View>
                  <TextInput
                    style={{
                      borderColor:
                        !childData.lastName || childData.lastName?.length < 2
                          ? 'red'
                          : 'rgba(112, 112, 112, 0.5)',
                      borderWidth: 1,
                      borderRadius: 15,
                      backgroundColor: Colors.White,
                      margin: 4,
                      marginVertical: 6,
                      paddingHorizontal: 6,
                      paddingVertical: 6
                    }}
                    value={childData.lastName}
                    onChangeText={(text) => {
                      setChildData({ ...childData, lastName: text.trim() });
                    }}
                  />
                  {!childData.lastName?.length && (
                    <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
                      Please enter a last name
                    </Paragraph2>
                  )}
                </View>
              </View>
            </Card>
            <Divider orientation="horizontal" />

            <FlatList
              data={fields}
              renderItem={({ item, index }) => (
                <View key={index} style={{ marginHorizontal: '2%' }}>
                  <Field
                    field={item}
                    displayFormError={false}
                    onChange={(value) => {
                      updatedFields[item.id] = value;
                      setUpdatedFields({ ...updatedFields });
                    }}
                  />
                  <Divider orientation="horizontal" />
                </View>
              )}
            />
            <View style={{ marginVertical: 20 }}>
              <Button onPress={onSave} loading={saving}>
                Save
              </Button>
            </View>
          </>
        ) : (
          <Loading />
        )}
      </ContentContainer>
    </ScrollView>
  );
}
