import React from 'react';
import { View, ViewProps } from 'react-native';
import { Smile } from 'react-native-feather';
import { useNavigator } from '../../compass/navigator';
import { Colors } from '../../constants';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';
import { Child } from '../../types';
import Button from '../buttons/button';
import Card from '../card';
import { Heading2, Heading3 } from '../text-family';

interface Props extends ViewProps {
  child: Child;
}

export default function ChildCard(props: Props) {
  const navigation = useNavigator();
  return (
    <Card {...composePropsWithDefaultStyles({}, props as any)}>
      <View
        style={{
          flexDirection: 'row',
          // justifyContent: "space-between",
          alignItems: 'center'
          // width: "90%",
        }}
      >
        <View style={{}}>
          <View
            style={{
              backgroundColor: 'rgba(6,255,255,0.5)',
              width: 40,
              height: 40,
              borderRadius: 50,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Smile
              width={30}
              height={30}
              color={Colors.Black}
              strokeWidth={1}
            />
          </View>
        </View>

        <Heading3 style={{ marginLeft: 10 }}>
          {props.child.lastName}, {props.child.firstName}
        </Heading3>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          width: '98%',
          marginTop: 8
        }}
      >
        <Button
          style={{ width: '45%' }}
          type="outline"
          onPress={() =>
            navigation.Go('ChildDetails', { childId: props.child.id })
          }
        >
          View Details
        </Button>
        {/* <Button style={{ width: '45%' }} type="outline">
          Edit Details
        </Button> */}
      </View>
    </Card>
  );
}
