import { useWindowSize } from './useWindowSize';
import { Screen } from '../constants';

export function breakpoint() {
  const [width] = useWindowSize();
  if (width < 768) {
    return Screen.sm;
  } else if (width > 768 && width < 1300) {
    return Screen.md;
  } else {
    return Screen.lg;
  }
}

export function buttonHorizontalWidth() {
  const breakpoi: string = breakpoint();
  switch (breakpoi) {
    case Screen.sm:
      return '60%';
    case Screen.md:
      return '40%';
    case Screen.lg:
      return '20%';
    default:
      return '100%';
  }
}
