import { ReactNode } from 'react';
import { View } from 'react-native';
import { Heading4, Paragraph1 } from './text-family';

interface Props {
  title: string;
  children: ReactNode;
}

export default function DescriptionItem({ title, children }: Props) {
  return (
    <View style={{ marginVertical: 4 }}>
      <Heading4 style={{ color: 'rgba(112, 112, 112, 1)', marginBottom: 2 }}>
        {title}
      </Heading4>
      <Paragraph1 style={{ marginBottom: 8, marginTop: 2 }}>
        {children}
      </Paragraph1>
    </View>
  );
}
