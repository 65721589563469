import { View, ViewProps } from 'react-native';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends ViewProps {
  orientation?: 'horizontal' | 'vertical';
}

export default function Divider(props: Props) {
  return props.orientation === 'vertical' ? (
    <View
      {...composePropsWithDefaultStyles(
        {
          height: '100%',
          borderRightWidth: 1,
          borderRightColor: 'rgba(0, 0, 0, 0.1)',
          marginHorizontal: 20
        },
        props as any
      )}
    />
  ) : (
    <View
      {...composePropsWithDefaultStyles(
        {
          width: '100%',
          borderBottomWidth: 1,
          borderBottomColor: 'rgba(0, 0, 0, 0.1)',
          marginVertical: 20
        },
        props as any
      )}
    />
  );
}
