import React from 'react';
import {
  TouchableOpacity,
  Text,
  TouchableOpacityProps,
  View
} from 'react-native';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';
import { ChevronLeft } from 'react-native-feather';
import { useNavigator } from '../../compass/navigator';

interface Props extends TouchableOpacityProps {}

export default function BackButton(props: Props) {
  const navigator = useNavigator();
  return (
    <View style={{ width: '30%' }}>
      <TouchableOpacity
        {...composePropsWithDefaultStyles({}, props as any)}
        onPress={() => navigator.Back()}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <ChevronLeft fontSize={16} color="black" style={{ marginRight: 4 }} />
          <Text style={{ fontSize: 16, color: 'black', fontWeight: '500' }}>
            {props.children}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
