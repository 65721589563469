import React, { useState } from 'react';
import { View } from 'react-native';
import { fieldIsValid } from '../../helpers/field-is-valid';
import { FieldProps, Validity } from '../../types';
import { Select } from '../select';
import { Paragraph2 } from '../text-family';

export default function SelectField({
  field,
  entry,
  onChange,
  displayFormError
}: FieldProps) {
  const [selection, setSelection] = useState<string | undefined>(
    entry ? (entry?.data as { selection: string }).selection : ''
  );
  const [error, setError] = useState<Validity>({
    valid: field.mandatory ? !!selection : true,
    message: 'This field is required'
  });
  return (
    <View>
      <Select
        style={{
          borderColor:
            displayFormError || !error.valid
              ? 'red'
              : 'rgba(112, 112, 112, 0.5)'
        }}
        selectedValue={selection}
        onValueChange={(selection) => {
          setSelection(selection);
          if (onChange) {
            onChange({ selection }, fieldIsValid(field, { selection }).valid);
          }
          setError(fieldIsValid(field, { selection }));
        }}
        items={(field.data as { selectOptions: string[] }).selectOptions.map(
          (item) => ({ label: item, value: item })
        )}
      />
      {field.mandatory ? (
        !error.valid ? (
          <Paragraph2 style={{ color: 'red', marginLeft: 10 }}>
            {error?.message}
          </Paragraph2>
        ) : null
      ) : null}
    </View>
  );
}
