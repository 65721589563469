import React from 'react';
import { Text, TextProps } from 'react-native';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends TextProps {}

export function Heading0(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 45,
          margin: 4,
          color: 'rgba(0, 0, 0, 1)',
          fontFamily: 'Poppins-ExtraBold'
        },
        props as any
      )}
    />
  );
}

export function Heading1(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 32,
          margin: 4,
          color: 'rgba(0, 0, 0, 1)',
          fontFamily: 'Poppins-SemiBold'
        },
        props as any
      )}
    />
  );
}

export function Heading2(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 20,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(0, 0, 0, 1)'
        },
        props as any
      )}
    />
  );
}

export function Heading3(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 18,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(112, 112, 112, 1)'
        },
        props as any
      )}
    />
  );
}

export function Heading4(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 16,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(112, 112, 112, 1)'
        },
        props as any
      )}
    />
  );
}

export function Heading5(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 14,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(112, 112, 112, 1)'
        },
        props as any
      )}
    />
  );
}

export function Heading6(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 12,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(112, 112, 112, 1)'
        },
        props as any
      )}
    />
  );
}

export function Heading7(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 10,
          margin: 4,
          fontFamily: 'Poppins-SemiBold',
          color: 'rgba(112, 112, 112, 1)'
        },
        props as any
      )}
    />
  );
}

export function Paragraph1(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 14,
          margin: 4,
          color: 'rgba(112, 112, 112, 1)',
          fontFamily: 'Poppins-Regular'
        },
        props as any
      )}
    />
  );
}

export function Paragraph2(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontSize: 12,
          marginHorizontal: 4,
          color: 'rgba(112, 112, 112, 1)',
          fontFamily: 'Poppins-Regular'
        },
        props as any
      )}
    />
  );
}
