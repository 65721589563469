import { FlatList, ScrollView, View } from 'react-native';
import { Smile } from 'react-native-feather';
import { useNavigator } from '../../compass/navigator';
import { Colors, Screen } from '../../constants';
import { breakpoint } from '../../helpers/breakpoint';
import getFieldData from '../../helpers/getFieldData';
import { useChild, useChildTenantEntries } from '../../hooks';
import useTenantId from '../../hooks/use-tenant-id';
import Button from '../buttons/button';
import Card from '../card';
import ContentContainer from '../content-container';
import Divider from '../divider';
import PageLoading from '../full-page-loading';
import Label from '../label';
import PageTitle from '../page-title';
import { Heading4, Heading5, Paragraph1 } from '../text-family';

export default function ChildDetails() {
  const navigator = useNavigator();
  const params = navigator.Params();
  const tenantId = useTenantId();
  const screenSize = breakpoint();
  const { data: child } = useChild(params.childId);
  const { data: fields } = useChildTenantEntries(params.childId, tenantId);
  return (
    <View style={{ flex: 1 }}>
      {!child && !fields ? (
        <PageLoading />
      ) : (
        <View style={{ flex: 1 }}>
          <PageTitle title="Child" />
          <ScrollView>
            <ContentContainer>
              <View style={{ flex: 1 }}>
                <FlatList
                  ListHeaderComponent={
                    <>
                      <Card>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <View
                            style={{
                              width: '70%',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: 'rgba(6,255,255,0.5)',
                                width: 40,
                                height: 40,
                                borderRadius: 50,
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Smile
                                width={30}
                                height={30}
                                color={Colors.Black}
                                strokeWidth={1}
                              />
                            </View>
                            <View
                              style={{
                                marginLeft: screenSize == Screen.lg ? 20 : 6
                              }}
                            >
                              <Heading4>{child?.firstName}</Heading4>
                            </View>
                          </View>
                          <View>
                            <Button
                              type="outline"
                              onPress={() =>
                                navigator.Go('ChildEdit', {
                                  childId: params.childId
                                })
                              }
                            >
                              Edit Details
                            </Button>
                          </View>
                        </View>
                      </Card>
                      <Divider orientation="horizontal" />
                    </>
                  }
                  data={fields}
                  renderItem={({ item, index }) => (
                    <View key={index}>
                      <View
                        style={{
                          flexDirection:
                            screenSize == Screen.lg ? 'row' : 'column',
                          justifyContent: 'space-between',
                          alignContent: 'flex-start',
                          width: '100%'
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            width: screenSize == Screen.lg ? '40%' : 'none'
                          }}
                        >
                          <Heading5
                            style={{ fontSize: 11, color: Colors.Gray }}
                          >
                            {item.field.name}
                          </Heading5>
                        </View>
                        <View
                          style={{
                            width: screenSize == Screen.lg ? '50%' : 'none',
                            alignItems:
                              screenSize == Screen.lg
                                ? 'flex-end'
                                : 'flex-start'
                          }}
                        >
                          <Paragraph1 style={{ color: Colors.Black }}>
                            {getFieldData(item) ? (
                              getFieldData(item)
                            ) : (
                              <Label type="warning">No Data</Label>
                            )}
                          </Paragraph1>
                        </View>
                      </View>
                      <Divider orientation="horizontal" />
                    </View>
                  )}
                />
              </View>
            </ContentContainer>
          </ScrollView>
        </View>
      )}
    </View>
  );
}
