import React, { useCallback } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AppContext from './src/context/app-context';
import Router from './src/router';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { View } from 'react-native';
import { NativeBaseProvider } from 'native-base';
import { Toaster } from 'react-hot-toast';

SplashScreen.preventAutoHideAsync();
Object.assign(global, { NODE_ENV: {}, process: {} });

export default function App() {
  const [fontsLoaded] = useFonts({
    'Poppins-Regular': require('./src/assets/fonts/Poppins-Regular.ttf'),
    'Poppins-SemiBold': require('./src/assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-ExtraBold': require('./src/assets/fonts/Poppins-ExtraBold.ttf')
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ flex: 1 }}>
      <NativeBaseProvider>
        <AppContext>
          <SafeAreaProvider>
            <Toaster />
            <Router />
          </SafeAreaProvider>
        </AppContext>
      </NativeBaseProvider>
    </View>
  );
}
