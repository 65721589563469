import React, { PropsWithChildren, useContext } from 'react';
import { useEffect, useState } from 'react';
import storage from '../storage/storage.isomorphic';

interface IAuthContext {
  loaded: boolean;
  token: string | null;
  tenantId: string | null;
  setTenantId(tenantId: string | null): void;
  setToken(token: string | null): void;
  clear(): void;
  refresh(): void;
}

const AuthContext = React.createContext<IAuthContext>({
  loaded: false,
  token: null,
  tenantId: null,
  setTenantId: () => {
    throw new Error('must be overriden');
  },
  setToken: () => {
    throw new Error('must be overriden');
  },
  clear: () => {
    throw new Error('must be overriden');
  },
  refresh: () => {
    throw new Error('must be overriden');
  }
});
export function useAuth() {
  return useContext(AuthContext);
}

interface Props {}

export function AuthContextProvider({ children }: PropsWithChildren<Props>) {
  const [token, setToken] = useState<string | null>(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);

  async function clear() {
    await storage.clear();
    setToken(null);
    setTenantId(null);
  }

  async function refresh() {
    async function fetchTokenAndTenant() {
      setToken(await storage.getItem('token'));
      setTenantId(await storage.getItem('tenantId'));
      setLoaded(true);
    }
    fetchTokenAndTenant();
  }

  useEffect(() => {
    async function fetchToken() {
      setToken(await storage.getItem('token'));
      setTenantId(await storage.getItem('tenantId'));
      setLoaded(true);
    }
    fetchToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{ token, tenantId, loaded, setToken, setTenantId, clear, refresh }}
    >
      {children}
    </AuthContext.Provider>
  );
}
