import React from 'react';
import { Picker } from '@react-native-picker/picker';
import { NativeSyntheticEvent, TargetedEvent, View, Text } from 'react-native';
import composePropsWithDefaultStyles from '../../helpers/compose-props-with-default-styles';

interface Props {
  onValueChange?: (value: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
  selectedValue?: string;
  disabled?: boolean;
  items: { label: string; value: any }[];
  placeholder?: { label: string; value: any };
}

export default function Select(props: Props) {
  return !props.disabled ? (
    <Picker
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: props.disabled ? '#ddd' : 'white',
          borderColor: 'rgba(112, 112, 112, 0.5)',
          borderWidth: 1,
          borderRadius: 20,
          margin: 4,
          paddingHorizontal: 12,
          marginVertical: 6,
          paddingRight: 25,
          paddingVertical: 6,
          color: 'rgba(112, 112, 112, 1)',
          fontFamily: 'Poppins-Regular'
        },
        props as any
      )}
      dropdownIconColor="rgba(112, 112, 112, 0)"
      onValueChange={props.onValueChange}
      onBlur={props.onBlur}
      selectedValue={props.selectedValue ?? ''}
    >
      <Picker.Item
        label={props.placeholder?.label}
        value={props.placeholder?.value}
      />
      {props.items.map((item, index) => (
        <Picker.Item key={index} label={item.label} value={item.value} />
      ))}
    </Picker>
  ) : (
    <View style={{ marginVertical: 4 }}>
      <Text
        style={{
          backgroundColor: props.disabled ? '#ddd' : 'white',
          borderColor: 'rgba(112, 112, 112, 0.5)',
          borderWidth: 1,
          borderRadius: 20,
          margin: 4,
          marginVertical: 6,
          paddingHorizontal: 6,
          paddingVertical: 8,
          color: 'rgba(112, 112, 112, 1)',
          fontFamily: 'Poppins-Regular'
        }}
      >
        {
          props.items.filter((item) => item.value === props.selectedValue)[0]
            .label
        }
      </Text>
    </View>
  );
}
